export default {
  project: {
    create: "CreateProject",
    edit: "EditProject",
    delete: "DeleteProject",
    visit: "VisitProject",
  },
  category: {
    create: "CreateCategory",
    edit: "EditCategory",
    delete: "DeleteCategory",
    visit: "VisitCategory"
  },
  panel: {
    create: "CreatePanel",
    edit: "EditPanel",
    delete: "DeletePanel",
    visit: "VisitPanel",
    filter: "EditPanelFilter",
    subscribe: "CreatePanelSubscription",
    showPanelSubscription: "ShowPanelSubscription"
  },
  report: {
    create: "CreateReport",
    edit: "EditReport",
    delete: "DeleteReport",
    visit: "VisitReport"
  },
  chart: {
    create: "CreateChart",
    edit: "EditChart",
    delete: "DeleteChart",
    visit: "VisitChart"
  },
  fact: {
    create: "CreateFact",
    edit: "EditFact",
    delete: "DeleteFact",
    visit: "VisitFact"
  },
  catalog: {
    create: "CreateCatalog",
    edit: "EditCatalog",
    delete: "DeleteCatalog",
    visit: "VisitCatalog",
    settings: "DataSettings"
  },
  user: {
    viewProjectMembers: "ViewProjectMembers",
    userManagement: "UserManagement"
  },
  group: {
    groupManagement: "GroupManagement"
  },
  log: {
    viewAuditLogs: "ViewAuditLogs"
  },
  schedule: {
    viewTimeline: "ViewTimeline"
  },
  system: {
    openIdManagement: "OpenIdManagement" 
  },
  subscription: {
    viewSubsPageAdmin: "ViewSubsPageAdmin",
    viewSubsPagePm: "ViewSubsPagePm",
    viewDetailSubsPage: "ViewDetailSubsPage",
    updateSubsPage: "UpdateSubsPage",
	  deleteSubsPage: "DeleteSubsPage",
	  activePassiveSubsPage: "ActivePassiveSubsPage"
  }
};
