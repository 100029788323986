<template>
  <v-menu
    offset-y
    bottom
    transition="slide-y-transition"
    data-test-id="apps-menu"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" data-test-id="apps-menu-btn">
        <v-icon data-test-id="apps-menu-icon">mdi-view-grid-outline</v-icon>
      </v-btn>
    </template>

    <v-card class="cardStyle ma-0 pa-0" data-test-id="apps-menu-card">
      <v-row class="ma-0 pa-0">
        <v-col
          v-for="(project, index) in projects"
          :key="project.id"
          cols="12"
          md="6"
          lg="6"
          xl="6"
          class="pointerStyle ma-0 pa-0"
          @click="navigateTo(project)"
        >
          <div class="box" :data-test-id="'apps-menu-item' + index">
            <v-img
              contain
              class="imageStyle"
              :src="project.avatar"
              :data-test-id="'apps-menu-item-img' + index"
            ></v-img>

            <div
              class="mt-1 text-center"
              style="font-size: 13px"
              :data-test-id="'apps-menu-item-title' + index"
            >
              {{ project.title }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      projects: []
    };
  },
  created() {
    this.load();
  },
  methods: {
    ...mapActions("app", ["selectProject", "unselectProject"]),
    navigateTo(project) {
      this.unselectProject();
      this.$storage.set("selectedProjectId", project.id);
      this.selectProject(project);
      this.$router
        .push({ name: "panels", params: { pid: project.id } })
        .catch(err => {
          //console 'a hatayı basmaması için eklendi.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log(err);
          }
        });
    },
    load() {
      this.$rest.get(
        { model: "projects", populateOptions: "getRestrictedProjects" },
        result => {
          this.projects = (result.body || [])
            .filter(item => item.permissions.length > 0)
            .sort((a, b) => {
              return a.title.localeCompare(b.title);
            });

          this.projects.forEach(project => {
            project.avatar = project.avatar
              ? project.avatar
              : "/images/project/project.jpg"; //public klasörü altına kopyaladım
          });
        }
      );
    }
  }
};
</script>

<style scoped>
.cardStyle {
  width: 240px;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}
.pointerStyle {
  cursor: pointer;
}
.imageStyle {
  max-height: 60%;
  max-width: 60%;
  height: 60px;
  width: 60px;
  margin-left: 22%;
}
.box {
  height: 110px;
  width: 110px;
  transition: box-shadow 0.3s;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
}
.box:hover {
  box-shadow: 0 0 11px #668ebe;
}
</style>
