process.env.HOST = 'localhost';
process.env.PORT = '8080'

module.exports = {
  server: {
    url: 'http://localhost:8000'
  },
  homeUrl: 'http://localhost:8080',
  // eGovernmentLoginURLOptions: {
  //   url: 'https://giris.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController?response_type=code&state=random_value&scope=Kimlik-Dogrula',
  //   clientId: '&client_id=c2f3b979-a709-4780-89c1-7dbd3817ddc3',
  //   redirectURI: `&redirect_uri=${encodeURIComponent('http://localhost/#/panels')}`
  // },
  storageName: 'localStorage',
  showUserManagement: true,
  showGroupSelection: true,
  showPanelRuleButton: false,
  useTokenLogin: false,
  enableLog: false,
  loginTypes: null,
  logoPath: 'foresight.png',
  showLogoAsInfo: false,
  showEGovernmentLogin: false,
  showApplicationLogin: true,
  //loginTypes: ['applicationLogin']
  // queryTokenParameterName: 'code',
  // customerLogoName: 'cbddoLogo',
  // customerText: 'TÜRKİYE CUMHURİYETİ CUMHURBAŞKANLIĞI Dijital Dönüşüm Ofisi',
  // customerSubtext: 'Kurum Portallerinde Sunulan Hizmetlerin Kullanım Sayılarının Analizi',
};
