var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "data-test-id": "container-" + _vm.index, fluid: "" } },
    [
      _c("v-autocomplete", {
        staticStyle: {
          height: "20px",
          "padding-top": "0px",
          "margin-top": "0px",
          "margin-bottom": "0px",
        },
        attrs: {
          items: _vm.filteredValues,
          "item-text": "title",
          "item-value": "value",
          dense: "",
          label: _vm.label,
          clearable: true,
          "data-test-id": "v-list-" + _vm.index,
        },
        on: { change: _vm.valueChanged, "click:clear": _vm.clearData },
        scopedSlots: _vm._u(
          [
            {
              key: `item`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g({ attrs: { dark: "" } }, on),
                                  [_vm._v(_vm._s(item.title))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(item.value))])]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.filterValue,
          callback: function ($$v) {
            _vm.filterValue = $$v
          },
          expression: "filterValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }