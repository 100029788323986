var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.menu, function (level1Item, level1Index) {
      return _c(
        "nav-menu-item",
        {
          key: level1Index,
          staticClass: "pt-1",
          attrs: {
            "menu-item": level1Item,
            "data-test-id": "nav-menu-item-level1-" + level1Index,
          },
        },
        [
          level1Item.items
            ? _vm._l(level1Item.items, function (level2Item, level2Index) {
                return _c(
                  "nav-menu-item",
                  {
                    key: level2Index,
                    attrs: {
                      "menu-item": level2Item,
                      subgroup: "",
                      small: "",
                      "data-test-id": "nav-menu-item-level2-" + level2Index,
                    },
                  },
                  [
                    level2Item.items
                      ? _vm._l(
                          level2Item.items,
                          function (level3Item, level3Index) {
                            return _c("nav-menu-item", {
                              key: level3Index,
                              attrs: {
                                "menu-item": level3Item,
                                small: "",
                                "data-test-id":
                                  "nav-menu-item-level3" + level3Index,
                              },
                            })
                          }
                        )
                      : _vm._e(),
                  ],
                  2
                )
              })
            : _vm._e(),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }