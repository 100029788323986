<template>
  <v-menu
    v-if="items.length > 0"
    offset-y
    bottom
    transition="slide-y-transition"
    data-test-id="tn-menu"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-badge bordered :content="items.length" offset-x="22" offset-y="22">
        <v-btn icon v-on="on" data-test-id="tn-bell-btn">
          <v-icon>mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <!-- dropdown card -->
    <v-card color="#16395b" data-test-id="tn-card">
      <v-list color="#16395b" three-line dense max-width="510">
        <div class="pa-2 font-weight-bold" data-test-id="tn-title">
          {{ $t("toolbar.notifications") }}
        </div>
        <div v-for="(item, index) in items" :key="index">
          <v-divider v-if="index > 0 && index < items.length" inset></v-divider>

          <v-list-item :data-test-id="'tn-list-' + index">
            <v-list-item-avatar
              :data-test-id="'tn-list-avatar-' + index"
              size="32"
              :color="item.color"
            >
              <v-icon dark small>{{ item.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content :data-test-id="'tn-list-content-' + index">
              <v-list-item-title
                v-text="item.title"
                :data-test-id="'tn-list-title-' + index"
              ></v-list-item-title>
              <v-list-item-subtitle
                :data-test-id="'tn-list-subtitle-' + index"
                class="caption"
                >{{ item.subtitle }}</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action
              :data-test-id="'tn-list-item-time-' + index"
              class="align-self-center"
            >
              <v-list-item-action-text
                v-text="item.time"
              ></v-list-item-action-text>
            </v-list-item-action>
            <v-list-item-action
              :data-test-id="'tn-list-item-delete-' + index"
              class="align-self-center"
            >
              <v-tooltip bottom color="blue">
                <template v-slot:activator="{ on }">
                  <v-btn
                    :data-test-id="'tn-delete-btn-' + index"
                    v-on="on"
                    small
                    icon
                    @click="deleteNotification(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span> {{ $t("toolbar.delete") }}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import utilities from "./../../utilities/utilities";
export default {
  data() {
    return {
     items: [
        {
          id: 1,
          title: "Abonelik İşlemleri",
          color: "success",
          icon: "mdi-email-send",
          subtitle:
            "'UAB Yolcu Aylık İstatistikler' adlı aboneliğe eklendiniz. Abonelik detayını Profil>Aboneliklerim sekmesinden görebilirsiniz.",
          time: "3 dk"
        },
        {
          id: 2,
          title: "Abonelik İşlemleri",
          color: "success",
          icon: "mdi-email-send",
          subtitle:
            "'UAB Yolcu Aylık İstatistikler' adlı abonelik pasif hale getirilmiştir.",
          time: "3 saat"
        },
        {
          id: 3,
          title: "Kullanıcı İşlemleri",
          color: "teal lighten-1",
          icon: "mdi-account",
          subtitle: "'Belgenet' adlı projeye üye oldunuz.",
          time: "1 gün"
        },
        {
          id: 4,
          title: "Grup İşlemleri",
          color: "primary",
          icon: "mdi-account-multiple",
          subtitle: "'Belgenet_User_Group' adlı gruba eklendiniz.",
          time: "3 gün"
        }
      ]
    };
  },
  methods: {
    deleteNotification(item) {
      this.items = utilities.clone(
          this.items.filter(value => value.id != item.id)
        );    
    },
  }
};
</script>
