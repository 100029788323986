var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "data-test-id": "container-" + _vm.index } },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-right": 40,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "290px",
            "data-test-id": "menu-" + _vm.index,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            label: _vm.label,
                            readonly: "",
                            "prepend-icon": "mdi-calendar",
                            clearable: "",
                            "data-test-id": "text-field-" + _vm.index,
                          },
                          on: { "click:clear": _vm.clearData },
                          model: {
                            value: _vm.dateText,
                            callback: function ($$v) {
                              _vm.dateText = $$v
                            },
                            expression: "dateText",
                          },
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" >")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _c("v-date-picker", {
            attrs: {
              color: "primary lighten-1",
              "header-color": "primary",
              "data-test-id": "date-picker-" + _vm.index,
            },
            on: { input: _vm.closeMenu },
            model: {
              value: _vm.dateData[0],
              callback: function ($$v) {
                _vm.$set(_vm.dateData, 0, $$v)
              },
              expression: "dateData[0]",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }