var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length > 0
    ? _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            bottom: "",
            transition: "slide-y-transition",
            "data-test-id": "tn-menu",
            "close-on-content-click": false,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-badge",
                      {
                        attrs: {
                          bordered: "",
                          content: _vm.items.length,
                          "offset-x": "22",
                          "offset-y": "22",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: {
                                icon: "",
                                "data-test-id": "tn-bell-btn",
                              },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-bell-outline")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            49833546
          ),
        },
        [
          _c(
            "v-card",
            { attrs: { color: "#16395b", "data-test-id": "tn-card" } },
            [
              _c(
                "v-list",
                {
                  attrs: {
                    color: "#16395b",
                    "three-line": "",
                    dense: "",
                    "max-width": "510",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pa-2 font-weight-bold",
                      attrs: { "data-test-id": "tn-title" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("toolbar.notifications")) + " "
                      ),
                    ]
                  ),
                  _vm._l(_vm.items, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        index > 0 && index < _vm.items.length
                          ? _c("v-divider", { attrs: { inset: "" } })
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          { attrs: { "data-test-id": "tn-list-" + index } },
                          [
                            _c(
                              "v-list-item-avatar",
                              {
                                attrs: {
                                  "data-test-id": "tn-list-avatar-" + index,
                                  size: "32",
                                  color: item.color,
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { dark: "", small: "" } },
                                  [_vm._v(_vm._s(item.icon))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              {
                                attrs: {
                                  "data-test-id": "tn-list-content-" + index,
                                },
                              },
                              [
                                _c("v-list-item-title", {
                                  attrs: {
                                    "data-test-id": "tn-list-title-" + index,
                                  },
                                  domProps: { textContent: _vm._s(item.title) },
                                }),
                                _c(
                                  "v-list-item-subtitle",
                                  {
                                    staticClass: "caption",
                                    attrs: {
                                      "data-test-id":
                                        "tn-list-subtitle-" + index,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.subtitle))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-action",
                              {
                                staticClass: "align-self-center",
                                attrs: {
                                  "data-test-id": "tn-list-item-time-" + index,
                                },
                              },
                              [
                                _c("v-list-item-action-text", {
                                  domProps: { textContent: _vm._s(item.time) },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-action",
                              {
                                staticClass: "align-self-center",
                                attrs: {
                                  "data-test-id":
                                    "tn-list-item-delete-" + index,
                                },
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "", color: "blue" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      "data-test-id":
                                                        "tn-delete-btn-" +
                                                        index,
                                                      small: "",
                                                      icon: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteNotification(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-delete"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("toolbar.delete"))
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }