var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { flat: "", height: "80" } },
        [
          _c(
            "v-container",
            { staticClass: "py-0 px-0 px-sm-2 fill-height" },
            [
              _c(
                "router-link",
                {
                  staticClass: "d-flex align-center text-decoration-none mr-2",
                  attrs: { to: "/dashboard" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/logo.png"),
                      height: "36",
                    },
                  }),
                ]
              ),
              _c("v-spacer"),
              _c(
                "div",
                { staticClass: "d-none d-md-block" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-1",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$vuetify.goTo("#pricing")
                        },
                      },
                    },
                    [_vm._v(" Pricing ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-1",
                      attrs: { text: "", to: "/auth/signin" },
                    },
                    [_vm._v(" Sign In ")]
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { outlined: "", large: "", to: "/auth/signup" } },
                [_vm._v(" Sign Up ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c("router-view", { key: _vm.$route.fullPath }),
          _c(
            "v-footer",
            { attrs: { color: "transparent" } },
            [
              _c(
                "v-container",
                { staticClass: "py-5" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "text-h6 text-lg-h5 font-weight-bold",
                          },
                          [_vm._v("Navigation")]
                        ),
                        _c("div", {
                          staticClass: "mb-5 mt-1 primary",
                          staticStyle: { width: "80px", height: "2px" },
                        }),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-wrap" },
                          _vm._l(_vm.links, function (link, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "w-half body-1 mb-1" },
                              [
                                link.to
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "text-decoration-none text--primary",
                                        attrs: { to: link.to },
                                      },
                                      [_vm._v(_vm._s(link.label))]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        staticClass:
                                          "text-decoration-none text--primary",
                                        attrs: {
                                          href: link.href,
                                          target: link.target || "blank",
                                        },
                                      },
                                      [_vm._v(_vm._s(link.label))]
                                    ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "text-h6 text-lg-h5 font-weight-bold",
                          },
                          [_vm._v("Contact Information")]
                        ),
                        _c("div", {
                          staticClass: "mb-5 mt-1 primary",
                          staticStyle: { width: "80px", height: "2px" },
                        }),
                        _c(
                          "div",
                          { staticClass: "d-flex mb-2 font-weight-bold" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: { color: "primary lighten-1" },
                              },
                              [_vm._v("mdi-map-marker-outline")]
                            ),
                            _vm._v(
                              " W Left Street, Long Beach, Los Angeles, CA 90802 USA "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex mb-2" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: { color: "primary lighten-1" },
                              },
                              [_vm._v("mdi-phone-outline")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "text-decoration-none text--primary",
                                attrs: { href: "#" },
                              },
                              [_vm._v("+03 000 23 00 555 55")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex mb-2" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: { color: "primary lighten-1" },
                              },
                              [_vm._v("mdi-email-outline")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "text-decoration-none text--primary",
                                attrs: { href: "#" },
                              },
                              [_vm._v("hello@loremcompany.com")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "text-h6 text-lg-h5 font-weight-bold",
                          },
                          [_vm._v("Newsletter")]
                        ),
                        _c("div", {
                          staticClass: "mb-5 mt-1 primary",
                          staticStyle: { width: "80px", height: "2px" },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column flex-lg-row w-full",
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "mr-lg-2",
                              attrs: {
                                outlined: "",
                                solo: "",
                                label: "Your email",
                                dense: "",
                                height: "44",
                              },
                            }),
                            _c(
                              "v-btn",
                              { attrs: { large: "", color: "primary" } },
                              [_vm._v("Subscribe")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-center text-md-right mt-4 mt-lg-2",
                          },
                          [
                            _vm._v(" Connect "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2",
                                attrs: { fab: "", small: "", color: "primary" },
                              },
                              [_c("v-icon", [_vm._v("mdi-twitter")])],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2",
                                attrs: { fab: "", small: "", color: "primary" },
                              },
                              [_c("v-icon", [_vm._v("mdi-linkedin")])],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2",
                                attrs: { fab: "", small: "", color: "primary" },
                              },
                              [_c("v-icon", [_vm._v("mdi-instagram")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "my-3" }),
                  _c("div", { staticClass: "text-center caption" }, [
                    _vm._v(" © Indielayer 2021. All Rights Reserved "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }