var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "data-test-id": "container-" + _vm.index, fluid: "" } },
    [
      _c("v-autocomplete", {
        staticStyle: {
          height: "20px",
          "padding-top": "0px",
          "margin-top": "0px",
          "margin-bottom": "0px",
        },
        attrs: {
          items: _vm.getPossibleValues,
          "item-text": "title",
          "item-value": "value",
          dense: "",
          label: _vm.label,
          multiple: "",
          clearable: true,
          "data-test-id": "v-list-" + _vm.index,
        },
        on: { change: _vm.valuesChanged, "click:clear": _vm.clearData },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function ({ item, index }) {
              return [
                index === 0
                  ? _c("span", [_vm._v(_vm._s(item.title))])
                  : _vm._e(),
                index === 1
                  ? _c("span", { staticClass: "grey--text caption pl-1" }, [
                      _vm._v("(+" + _vm._s(_vm.filterValues.length - 1) + ")"),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item",
            fn: function (data) {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-layout",
                                _vm._g(
                                  _vm._b(
                                    { attrs: { wrap: "" } },
                                    "v-layout",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("v-checkbox", {
                                        model: {
                                          value: data.attrs.inputValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              data.attrs,
                                              "inputValue",
                                              $$v
                                            )
                                          },
                                          expression: "data.attrs.inputValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(data.item.title)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_c("span", [_vm._v(_vm._s(data.item.value))])]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.filterValues,
          callback: function ($$v) {
            _vm.filterValues = $$v
          },
          expression: "filterValues",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }