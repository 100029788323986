export default [{
  path: '/users',
  redirect: '/users/list'
}, {
  path: '/users/list',
  name: 'userslist',
  component: () => import(/* webpackChunkName: "userspage" */ "@/pages/users/UsersPage.vue")
}, {
  path: '/user/edit',
  name: 'users-edit',
  component: () => import(/* webpackChunkName: "users-edit" */ '@/pages/users/EditUserPage.vue')
}, {
  path: '/users/update/:id',
  name: 'userupdate',
  component: () => import(/* webpackChunkName: "userupdate" */ '@/pages/users/UserUpdatePage.vue')
}, {
  path: '/groups',
  name: 'groups',
  component: () => import(/* webpackChunkName: "groups" */ "@/pages/groups/GroupsPage.vue")
}, {
  path: '/groups/update/:id',
  name: 'groupupdate',
  component: () => import(/* webpackChunkName: "groupupdate" */ '@/pages/groups/GroupUpdatePage.vue')
},
{
  path: '/projcatauth',
  name: 'proj-cat-auth',
  component: () => import(/* webpackChunkName: "projcatauth" */ "@/pages/authorization/ProjectCategoryAuth.vue")
},
{
  path: '/projectmembers',
  name: 'project-members',
  component: () => import(/* webpackChunkName: "projectmembers" */ "@/pages/authorization/ProjectMembers.vue")
},
{
  path: '/projectauth',
  name: 'project-auth',
  component: () => import(/* webpackChunkName: "projectauth" */ "@/pages/authorization/ProjectAuth.vue")
},
]
