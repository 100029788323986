<template>
  <v-menu
    offset-y
    left
    transition="slide-y-transition"
    style="position: relative; z-index: 999999"
    data-test-id="tu-menu"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon class="elevation-2" v-on="on" data-test-id="tu-btn">
        <v-badge color="success" dot bordered offset-x="10" offset-y="10">
          <v-avatar
            :style="{ backgroundColor: user.avatarColor }"
            size="40"
            data-test-id="tu-avatar"
          >
            {{ getUserAvatar() }}
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>

    <!-- user menu list -->
    <v-list dense nav>
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        :to="item.link"
        :exact="item.exact"
        :disabled="item.disabled"
        link
        :data-test-id="'tu-list-' + index"
      >
        <v-list-item-icon>
          <v-icon
            :data-test-id="'tu-list-icon-' + index"
            small
            :class="{ 'grey--text': item.disabled }"
            >{{ item.icon }}</v-icon
          >
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title :data-test-id="'tu-list-title-' + index">{{
            item.key ? $t(item.key) : item.text
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1"></v-divider>

      <v-list-item @click="logout" data-test-id="tu-list-logout">
        <v-list-item-icon>
          <v-icon small data-test-id="tu-list-logout-icon"
            >mdi-logout-variant</v-icon
          >
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title data-test-id="tu-list-logout-title">{{
            $t("menu.logout")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import config from "../../configs";
import config2 from "./../../configs/app/config";
import { mapActions } from "vuex";

/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
export default {
  data() {
    return {
      menu: config.toolbar.user,
      user: {
        name: "",
        surname: "",
        avatarColor: "",
      },
    };
  },
  created() {
    this.getUserNameSurname();
  },
  methods: {
    ...mapActions("app", ["unselectProject","resetUserPermissions"]),
    logout() {
      if (this.$storage.get("session.loginType") === config2.loginTypes[0]) {
        //oidc
        this.$storage.clear();
        this.unselectProject();
        this.resetUserPermissions();
        const logoutOptions = {
          redirectUri: config2.keycloak.config.redirectUrl,
        };
        console.log("-->  logout options ", logoutOptions);
        console.log("--> keycloak object ", this.$keycloak);
        let keycloakLogoutFunc = this.$keycloak.logoutFn || this.$keycloak.keycloak.logout;
        const token = this.$keycloak.keycloak.token;
        this.$rest.patch(
          { model: "authenticate/oidc" },
          {
            token: token
          },
          (result) => {
            keycloakLogoutFunc(logoutOptions)
              .then((success) => {
                console.log("-->  logout success ", success);
              })
              .catch((error) => {
                console.log("-->  logout error ", error);
            });
            console.log("Kullanıcı çıkış yaptı!!!", token);
          },
          (error) => {
            console.log("OIDC logout hata oluştu!!!", error);
          }
        );
      } else {
        const token = this.$storage.get("session.token");
        this.$rest.patch(
          { model: "authenticate/applicationLogin" },
          {
            token: token
          },
          (result) => {
            console.log("Kullanıcı çıkış yaptı!!!", token);
          }
        );
        this.$storage.clear();
        this.unselectProject();
        this.resetUserPermissions();

        this.$router.push({ name: "login" }).catch((err) => {
          //console 'a hatayı basmaması için eklendi.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            this.$router.push({ name: "login" });
            console.log(err);
          }
        });
      }
    },
    getUserNameSurname() {
      this.user.username = this.$storage.get("user.username");
      this.user.name = this.$storage.get("user.name");
      this.user.surname = this.$storage.get("user.surname");
      this.user.avatarColor = this.$storage.get("user.avatarColor");
    },
    getUserAvatar() {
      if (this.user.username == "admin") {
        return "AD";
      } else if (this.user.username != "admin") {
        return `${this.user.name.charAt(0)}${
          this.user.surname ? this.user.surname.charAt(0) : ""
        }`;
      } else return "";
    },
  },
};
</script>
