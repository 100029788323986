process.env.HOST = '192.168.100.42';
process.env.PORT = '80'

module.exports = {
  server: {
    url: 'https://ekoopharita.tarimkredi.org.tr:8443'
  },
  homeUrl: 'https://ekoopharita.tarimkredi.org.tr',
  storageName: 'sessionStorage',
  showUserManagement: true,
  showGroupSelection: false,
  showPanelRuleButton: true,
  useTokenLogin: true,
  enableLog: true,
  loginTypes: ['ekoopLogin', 'applicationLogin'],
  logoPath: 'tkkmb.png',
  showLogoAsInfo: true
};