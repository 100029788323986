import Vue from "vue";
import Router from "vue-router";

// Routes
import PagesRoutes from "./pages.routes";
import UsersRoutes from "./users.routes";

Vue.use(Router);

let application = new Vue();

export const routes = [
  {
    path: "/",
    redirect: "/project/list"
  },
  {
    path: "/project",
    redirect: "/project/list"
  },
  {
    path: "/project/list",
    name: "projects",
    component: () =>
      import(
        /* webpackChunkName: "project" */ "@/pages/project/ProjectPage.vue"
      )
  },
  {
    path: "/panels/:pid",
    name: "panels",
    component: () =>
      import(
        /* webpackChunkName: "panels2" */ "@/pages/dashboard/PanelsPage.vue"
      )
  },
  {
    path: "/categories/:id",
    name: "categories",
    component: () =>
      import(
        /* webpackChunkName: "panels2" */ "@/pages/dashboard/PanelsPage.vue"
      )
  },

  {
    path: "/panel/:id",
    name: "panel",
    component: () =>
      import(
        /* webpackChunkName: "widgets" */ "@/pages/dashboard/PanelPage.vue"
      ),
    meta: {
      layout: "widget"
    }
  },
  {
    path: "/widgetpanel/:id&:wid",//modeli kullanan içerikler ekranındaki a href için tanımlandı.
    name: "widgetpanel",
    component: () =>
      import(
        /* webpackChunkName: "widgetpanel" */ "@/pages/dashboard/PanelPage.vue"
      ),
    meta: {
      layout: "widget"
    }
  },
  {
    path: "/catalog",
    name: "catalog",
    component: () =>
      import(
        /* webpackChunkName: "catalog" */ "@/pages/catalog/CatalogPage.vue"
      )
  },
  {
    path: "/model/:id",
    name: "model",
    component: () =>
      import(
        /* webpackChunkName: "model" */ "@/pages/model/ModelPage.vue"
      )  
  },
  {
    path: "/modeldetail/:id/:cid/:title",
    name: "modeldetail",
    component: () =>
      import(
        /* webpackChunkName: "modeldetail" */ "@/pages/model/ModelDetailPage.vue"
      ),  
  
  },
  {
    path: "/modeldetail/:id/:cid/:schema/:table/:title",
    name: "modeldetail",
    component: () =>
      import(
        /* webpackChunkName: "modeldetail" */ "@/pages/model/ModelDetailPage.vue"
      ),  
  
  },
  {
    path: "/modelupdate/:factId/:cid",
    name: "modelupdate",
    component: () =>
      import(
        /* webpackChunkName: "modelupdate" */ "@/pages/model/ModelUpdatePage.vue"
      ),  
  },
  {
    path: "/search/:text",
    name: "search",
    component: () =>
      import(
        /* webpackChunkName: "search" */ "@/pages/search/SearchPage.vue"
      )
  },
  {
    path: '/subspm',
    name: 'subspm',
    component: () => import(/* webpackChunkName: "subspm" */ "@/pages/subscription/SubscriptionPagePM.vue")
  },
  {
    path: '/subs',
    name: 'subs',
    component: () => import(/* webpackChunkName: "subs" */ "@/pages/subscription/SubscriptionPage.vue")
  },
  {
    path: '/subs/:id',
    name: 'subs',
    component: () => import(/* webpackChunkName: "subs" */ "@/pages/subscription/SubscriptionPage.vue")
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import(/* webpackChunkName: "schedule" */ "@/pages/schedule/SchedulePage.vue")
  },
  {
    path: '/openid',
    name: 'openid',
    component: () => import(/* webpackChunkName: "openid" */ "@/pages/openid/OpenIdPage.vue")
  },
  {
    path: '/audit',
    name: 'audit',
    component: () => import(/* webpackChunkName: "audit" */ "@/pages/audit/AuditLogPage.vue")
  },
  {
    path: '/datasettings',
    name: 'datasettings',
    component: () => import(/* webpackChunkName: "datasettings" */ "@/pages/catalog/DataSettingPage.vue")
  },
  ...PagesRoutes,
  ...UsersRoutes,
  {
    path: "/blank",
    name: "blank",
    component: () =>
      import(/* webpackChunkName: "blank" */ "@/pages/BlankPage.vue")
  },
  {
    path: "/unexpected",
    name: "unexpected",
    component: () =>
      import(/* webpackChunkName: "unexpected" */ "@/pages/error/UnexpectedPage.vue"),
    meta: {
      layout: "error"
    }
  },
  {
    path: "*",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/pages/error/NotFoundPage.vue"),
    meta: {
      layout: "error"
    }
  }
];

const router = new Router({
  mode: "history",
  duplicateNavigationPolicy: 'reload',
  base: process.env.BASE_URL || "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes
});

/**
 * Before each route update
 */

router.beforeEach(function(to, from, next) {
  if (to.params.token && !(from.name || "").includes("Login")) {
    application.$storage.remove("session.token");
  }

  const authenticated = application.$storage.get("session.token");
//sayfa yönlendirmelerinde aşağıdaki if kontrolü güncellenecek
if(!authenticated && to.name !== 'login' && to.name !== 'unexpected' && to.name !== 'auth-forgot' && to.name !== 'password' && to.name !== 'ldap'){
    return next("login", true);
  }else if (authenticated && to.name === "login") {
    return next(from.path);
  } 
  else {
    return next();
  }
});

/**
 * After each route update
 */
router.afterEach((to, from) => {});

export default router;
