<template>
  <v-container :data-test-id="'container-' + index" fluid>
    <v-autocomplete
      style="
        height: 20px;
        padding-top: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
      "
      v-model="filterValue"
      :items="filteredValues"
      item-text="title"
      item-value="value"
      dense
      :label="label"
      @change="valueChanged"
      :clearable="true"
      @click:clear="clearData"
      :data-test-id="'v-list-' + index"
    >
      <template v-slot:[`item`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span dark v-on="on">{{ item.title }}</span>
          </template>
          <span>{{ item.value }}</span>
        </v-tooltip>
      </template>
    </v-autocomplete>
  </v-container>
</template>

<script>
export default {
  props: [
    "value",
    "index",
    "active",
    "possibleValues",
    "themed",
    "nameField",
    "disabled",
    "label"
  ],
  computed: {
    filteredValues() {
      if (this.nameField) {
        let values = (this.possibleValues || []).filter(
          value =>
            value &&
            value[this.nameField]
              .toString()
              .toLocaleLowerCase('tr-TR')
              .includes(this.filter.toLocaleLowerCase('tr-TR'))
        );
        const computedValues = (values || []).map(value => {
          if (value.length > 35)
            return {
              title: value.substring(0, 35).concat(" ..."),
              value: value
            };
          else return { title: value, value: value };
        });
        return computedValues;
      } else {
        let values = (this.possibleValues || []).filter(
          value =>
            value &&
            value
              .toString()
              .toLocaleLowerCase('tr-TR')
              .includes(this.filter.toLocaleLowerCase('tr-TR'))
        );
        const computedValues = (values || []).map(value => {
          if (value.length > 35)
            return {
              title: value.substring(0, 35).concat(" ..."),
              value: value
            };
          else return { title: value, value: value };
        });
        return computedValues;
      }
    },
    filterValueName() {
      let valueName = this.filterValue;

      if (this.nameField) {
        valueName = this.filterValue[this.nameField];
      }

      return valueName;
    }
  },
  watch: {
    value: function(newVal, oldVal) {
      this.filterValue = newVal || "";
    }
  },
  created() {
    this.filterValue = this.value || "";
  },
  data() {
    return {
      isActive: false,
      filter: "",
      filterValue: ""
    };
  },
  methods: {
    toggleDropdown(state) {
      this.isActive = state && !this.disabled;
      this.filter = "";
      this.$emit("index-changed", this.isActive ? this.index : -1);
    },
    valueChanged() {
      this.$emit("index-changed", this.index);
      this.$emit("value-changed", this.filterValue);
    },
    emit(event) {
      this.$eventBus.$emit(event);
    },
    valuesName(values) {},
    clearData() {
      this.filterValue = "";
      this.$emit("value-changed", this.filterValue);
    }
  }
};
</script>
<style scoped>
.container {
  padding-left: 0px;
  padding-right: 10px;
  max-width: 350px;
  min-width: 200px;
  z-index: 99999;
}
</style>
