import utility from "./../utilities/utilities";
import Vue from "vue";
import json from "vue-resource/src/http/interceptor/json";

let sessionUserKey;
let application = new Vue();

function handleSuccess(callback) {
  return function(response) {
    if (response.status === 401) {
      application.$storage.clear();
      application.$eventBus.$emit("login"); //main.js de this.$eventBus.$on('login') handle ediliyor.
    } else if (callback) {
      callback(response);
    }
  };
}

function handleError(callback) {
  return function(error) {
    if (error.body && error.body.status === "UNAUTHORIZED") {

      if (error.body.error === "E_TIME_OUT") {
        /* utility.showErrorToast(
          application.$strings.get().login.sessionTimeOut,
          application
        );//main.js de handle ediliyor*/

        application.$storage.clear();
        application.$eventBus.$emit("session");
      } else if (error.body.error === "E_USER_NOT_ACTIVE") {
        utility.showErrorToast(
          application.$strings.get().login.userNotActive,
          application
        );
        application.$storage.clear();
        application.$eventBus.$emit("login");
      } else if (error.body.error === "E_USER_INITIALIZED") {
        application.$eventBus.$emit("passwordChange", error.body.errorData);
      }
      else {
        application.$storage.clear();
        application.$eventBus.$emit("login");
      }
      
    }
    else if (error === "UNAUTHORIZED") {//ldap ile girişte session timeout olduğu zaman login sayfasına yönlendirmek için eklendi.
      application.$storage.clear();
      application.$eventBus.$emit("login");

    } else if (error.status === 401) {
      application.$eventBus.$emit("login");
    } else if (error.status === 400) {
      if (error.body.error === "E_USERNAME_PASSWORD_WRONG") {
        utility.showErrorToast(
          application.$strings.get().passwordPage.passwordInvalid,
          application
        );
      } else if (error.body.error === "E_MODEL_INVALID") {
        utility.showErrorToast(
          application.$strings.get().error.expansionInvalidMessage,
          application
        );
      } else if (error.body.error === "E_USER_EXISTS") {
        utility.showErrorToast(
          application.$strings.get().error.userExistsError,
          application
        );
      }else {
        let msg = JSON.parse(error.bodyText);
        if (!msg.data && msg.message) {
          utility.showErrorToast(msg.message, application);
        }
      }
    } else if (error.status === 412 && error.bodyText === "USER_NOT_FOUND") {
      utility.showErrorToast(
        application.$strings.get().login.userNotFound,
        application
      );
    } else if (error.status === 412 && error.bodyText === "USER_ACTIVATED") {
      utility.showErrorToast(
        application.$strings.get().login.userActivated,
        application
      );
    } else if (error.status === 412 && error.bodyText === "TOKEN_FAIL") {
      utility.showErrorToast(
        application.$strings.get().login.tokenFail,
        application
      );
    } else if (error.status === 412 && error.bodyText === "TOKEN_EXPIRED") {
      utility.showErrorToast(
        application.$strings.get().login.tokenExpired,
        application
      );
    } else if (error.status === 412 && error.bodyText === "E_USERNAME_PASSWORD_WRONG") {
      utility.showErrorToast(
        application.$strings.get().login.initialPasswordWrong,
        application
      );
    } else if (error.status === 412 && error.body.error === "E_USER_INITIALIZED") {
        utility.showErrorToast(
          application.$strings.get().login.userInitialized,
          application
        );
    } else if (error.status === 412 && error.body.error === "CONTENT_USED_IN_USER") {//domain ismi bir ldap kullanıcısı için kullanılmışsa 
      utility.showErrorToast(
        application.$strings.get().openId.domainUsedInUser,
        application
      );
    }else if (error.status === 412) {
      utility.showErrorToast(
        application.$strings.get().login.userUnauthorized,
        application
      );
    } else if (error.status === 406 && error.data == null) {
      //NOT_ACCEPTABLE 406 dönüyor ProjectPage'de handle edildi.yetki tanımlı olan projenin silinmek istendiği durumda dönüyor.
      utility.showErrorToast(
        application.$strings.get().login.invalidDatabaseConnection,
        application
      );
    } else if (error.status === 403) {
      application.$storage.clear();
      application.$eventBus.$emit("login");
    } else if (error.status === 500) {
      if (error.bodyText) {
        let msg = JSON.parse(error.bodyText);
        if (msg.status === error.status && msg.message) {
          utility.showErrorToast(msg.message, application);
        } else if (msg.message)
          //TODO: DBde kayıtlı olmayan kullanıcı giriş yapmak istediği zaman backend 500 dönüyor. 400 dönmesi lazım. FORSP-1606 maddesi açıldı backend için.
          utility.showErrorToast(msg.message, application);
        else {
          //internal server error hatasında unexpected sayfasına yönlendirir.
         /* application.$storage.clear();//geçici olarak kapatıldı. sonra açılacak
          application.$eventBus.$emit("unexpected");*/
          utility.showErrorToast("Hata oluştu!!!!", application);
        }
      }
      else utility.showErrorToast("Hata oluştu!!!!", application);
    }


    if (callback) {
      callback(error);
    }
  };
}

export default {
  install: function(Vue, url, userKey) {
    sessionUserKey = userKey;

    Vue.prototype.$rest = {
      get: function(options, successCallback, errorCallback) {
        return Vue.http
          .get(url + utility.urlBuilder(options), {
            headers: {
              transaction: options.transaction
            }
          })
          .then(handleSuccess(successCallback))
          .catch(handleError(errorCallback));
      },
      post: function(options, data, successCallback, errorCallback) {
        return Vue.http
          .post(url + utility.urlBuilder(options), data, {
            headers: {
              transaction: options.transaction
            }
          })
          .then(handleSuccess(successCallback))
          .catch(handleError(errorCallback));
      },
      patch: function(options, data, successCallback, errorCallback) {
        return Vue.http
          .patch(url + utility.urlBuilder(options), data, {
            headers: {
              transaction: options.transaction
            }
          })
          .then(handleSuccess(successCallback))
          .catch(handleError(errorCallback));
      },
      delete: function(options, successCallback, errorCallback) {
        return Vue.http
          .delete(url + utility.urlBuilder(options), {
            headers: {
              transaction: options.transaction
            }
          })
          .then(handleSuccess(successCallback))
          .catch(handleError(errorCallback));
      }
    };
  }
};
