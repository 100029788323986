<template>
  <v-container :data-test-id="'container-' + index">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
      :data-test-id="'menu-' + index"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="dateRangeText"
          :label="label"
          readonly
          v-on="on"
          prepend-icon="mdi-calendar"
          clearable
          @click:clear="clearData"
          :data-test-id="'text-field-' + index"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="datesData"
        range
        color="primary lighten-1"
        header-color="primary"
        @input="closeMenu"
        :data-test-id="'date-picker-' + index"
      ></v-date-picker>
    </v-menu>
  </v-container>
</template>
<script>
export default {
  props: ["dates", "label", "index"],
  data: () => ({
    menu: false,
    datesData: []
  }),
  methods: {
    closeMenu() {
      if (this.datesData[1]) {
        this.menu = false;
        this.$emit("value-changed", this.datesData);
      }
    },
    emit(event) {
      this.$eventBus.$emit(event);
    },
    clearData() {
      this.datesData = [];
      this.$emit("value-changed", this.datesData);
    }
  },
  watch: {
    dates: {
      immediate: false,//false olması gerekiyor dates set edilmeği takdirde hata veriyor
      handler(newVal, oldVal) {
        this.datesData = newVal;
      }
    }
  },
  computed: {
    dateRangeText: {
      get() {
        let date1 = "";
        if (this.datesData[0])
          date1 =
            new Date(this.datesData[0]).getDate() +
            "." +
            (new Date(this.datesData[0]).getMonth() + 1) +
            "." +
            new Date(this.datesData[0]).getFullYear() +
            " - ";

        let date2 = "";
        if (this.datesData[1])
          date2 =
            new Date(this.datesData[1]).getDate() +
            "." +
            (new Date(this.datesData[1]).getMonth() + 1) +
            "." +
            new Date(this.datesData[1]).getFullYear();
        const date = date1 + date2;
        return date;
      },
      set(newValue) {
        let date1 = "";
        if (newValue && newValue[0])
          date1 =
            new Date(newValue[0]).getDate() +
            "." +
            (new Date(newValue[0]).getMonth() + 1) +
            "." +
            new Date(newValue[0]).getFullYear() +
            " - ";

        let date2 = "";
        if (newValue && newValue[1])
          date2 =
            new Date(newValue[1]).getDate() +
            "." +
            (new Date(newValue[1]).getMonth() + 1) +
            "." +
            new Date(newValue[1]).getFullYear();
        const date = date1 + date2;
        return date;
      }
    }
  }
};
</script>
<style scoped>
.container {
  padding-left: 0px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 300px;
  min-width: 200px;
  z-index: 99999;
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
</style>
