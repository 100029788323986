var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "", left: "", transition: "slide-y-transition" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: {
                      text: "",
                      icon: _vm.$vuetify.breakpoint.smAndDown,
                    },
                  },
                  on
                ),
                [
                  _c("flag-icon", {
                    attrs: {
                      "data-test-id": "flag-icon",
                      round: _vm.$vuetify.breakpoint.smAndDown,
                      flag: _vm.currentLocale().flag,
                    },
                  }),
                  _vm.showArrow
                    ? _c(
                        "v-icon",
                        { attrs: { "data-test-id": "arrow-icon", right: "" } },
                        [_vm._v("mdi-chevron-down")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "", nav: "" } },
        _vm._l(_vm.locales, function (locale, index) {
          return _c(
            "v-list-item",
            {
              key: locale.code,
              on: {
                click: function ($event) {
                  return _vm.setLocale(locale.code)
                },
              },
            },
            [
              _c("flag-icon", {
                class: [_vm.$vuetify.rtl ? "ml-1" : "mr-1"],
                attrs: { kIndex: index + 1, flag: locale.flag },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }