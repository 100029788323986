var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        bottom: "",
        transition: "slide-y-transition",
        "data-test-id": "apps-menu",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  { attrs: { icon: "", "data-test-id": "apps-menu-btn" } },
                  on
                ),
                [
                  _c(
                    "v-icon",
                    { attrs: { "data-test-id": "apps-menu-icon" } },
                    [_vm._v("mdi-view-grid-outline")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        {
          staticClass: "cardStyle ma-0 pa-0",
          attrs: { "data-test-id": "apps-menu-card" },
        },
        [
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0" },
            _vm._l(_vm.projects, function (project, index) {
              return _c(
                "v-col",
                {
                  key: project.id,
                  staticClass: "pointerStyle ma-0 pa-0",
                  attrs: { cols: "12", md: "6", lg: "6", xl: "6" },
                  on: {
                    click: function ($event) {
                      return _vm.navigateTo(project)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "box",
                      attrs: { "data-test-id": "apps-menu-item" + index },
                    },
                    [
                      _c("v-img", {
                        staticClass: "imageStyle",
                        attrs: {
                          contain: "",
                          src: project.avatar,
                          "data-test-id": "apps-menu-item-img" + index,
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "mt-1 text-center",
                          staticStyle: { "font-size": "13px" },
                          attrs: {
                            "data-test-id": "apps-menu-item-title" + index,
                          },
                        },
                        [_vm._v(" " + _vm._s(project.title) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }