import Vue from 'vue'
import Vuex from 'vuex'

// Global vuex
import AppModule from './app'
import VueResource from 'vue-resource';
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)
Vue.use(VueResource);
/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
    modules: {
        app: AppModule
    },
    plugins: [createPersistedState()] //page refresh edildiği zaman state bilgilerinin kaybolmaması için eklendi.
})

export default store