export default {
  install(Vue) {
    const application = new Vue();

    Vue.prototype.$eventBus = {
      $on: application.$on,
      $off: application.$off,
      $emit: application.$emit,
      _events: application._events
    };
  }
}
