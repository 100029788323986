import Vue from 'vue'
import VueResource from 'vue-resource';
import Toast from "vue-toastification";
import VueSelectImage from "vue-select-image";
import "vue-select-image/dist/vue-select-image.css";
import "vue-toastification/dist/index.css";
import App from './App.vue'
import Rest from './plugins/rest'
import Storage from './plugins/storage'
import EventHub from './plugins/eventHub';
import Sortable from 'vue-sortable';
import ECharts from 'vue-echarts/components/ECharts'
import VueObserveVisibility from 'vue-observe-visibility';
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js';
import ZkTable from "vue-table-with-tree-grid-icon";
import vueTabulator from 'vue-tabulator';

// import ECharts modules manually to reduce bundle size
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/map';
import 'echarts/lib/chart/radar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/axis';
import 'echarts/lib/component/axisPointer';
import 'echarts/lib/component/dataset';
import 'echarts/lib/component/geo';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/radar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/visualMap';
import 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'tabulator-tables/dist/css/tabulator_midnight.min.css'
// VUEX - https://vuex.vuejs.org/
import store from './store'

// VUE-ROUTER - https://router.vuejs.org/
import router from './router'

// PLUGINS
import vuetify from './plugins/vuetify'
import i18n from './plugins/vue-i18n'
import './plugins/echarts' //kullanılıyor

import './plugins/vue-google-maps'
import './plugins/vue-shortkey'
import './plugins/vue-head'
import './plugins/vue-gtag'
import './plugins/apexcharts'

import './plugins/animate'
import './plugins/clipboard'
import './plugins/moment'
import Strings from './plugins/strings';

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/formatCurrency'
import './filters/formatDate'
import utility from "./utilities/utilities";

// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss'

// Animation library - https://animate.style/
import 'animate.css/animate.min.css'

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false

import config from './configs/app/config';


let application = new Vue();
Vue.use(VueSelectImage);
Vue.use(VueResource);
Vue.use(Rest, config.server.url, 'session.token');
Vue.use(Storage);
Vue.use(EventHub);
Vue.use(vueTabulator, { name: "vue-tabulator" });
Vue.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
});
Vue.use(Strings);
Vue.use(Sortable);
Vue.use(VueObserveVisibility);
Vue.use(ZkTable);
Vue.use(VueKeycloakJs, {
  init: config.keycloak.init,
  config: config.keycloak.config,
  /*
  onAuthSuccess: function(keycloak){
    alert("oidc login success");
    console.log("keycloak::onAuthSuccess ", keycloak);
    application.$rest.post(
        { model: "authenticate/oidc" },
        {
          username: application.$storage.get("user.username")
        },
        (result) => {
          console.log("Kullanıcı giriş yaptı: OIDC");
        },
        (error) => {
          console.log("OIDC login hata oluştu!!!", error);
        }
    );
  },
  onAuthLogout: function(keycloak){
    alert("oidc logout");
    console.log("keycloak::onAuthLogout ", keycloak);
    const token = keycloak.token;
    this.$rest.patch(
      { model: "authenticate/oidc" },
      {
        token: token
      },
      (result) => {
        console.log("Kullanıcı çıkış yaptı!!!", token);
      },
      (error) => {
        console.log("OIDC logout hata oluştu!!!", error);
      }
    );
  },*/
  onReady: function(keycloak) {
    console.log("keycloak:",keycloak);
    if( keycloak.token ){
      console.log("keycloak.tokenParsed:",keycloak.tokenParsed);
      application.$storage.set('session.token', keycloak.token);
      application.$storage.set('session.loginType', config.loginTypes[0]);//0: oidc
      application.$storage.set('user.username', keycloak.tokenParsed.preferred_username);
      application.$storage.set('user.name', keycloak.tokenParsed.given_name);
      application.$storage.set('user.surname', keycloak.tokenParsed.family_name);
      //application.$eventBus.$emit("ldapLogin");
      application.$rest.post(
          { model: "authenticate/oidc" },
          {
            username: application.$storage.get("user.username")
          },
          (result) => {
            console.log("Kullanıcı giriş yaptı: OIDC");
            router.push({ name: "projects" }).catch((err) => {
                if (
                  err.name !== "NavigationDuplicated" &&
                  !err.message.includes(
                    "Avoided redundant navigation to current location"
                  )
                ) {
                  console.log(err);
                }
            });
          },
          (error) => {
            console.log("OIDC login hata oluştu!!!", error);
          }
      );
    }
  }
});


Vue.component('chart', ECharts);

Vue.http.interceptors.push((request, next) => {
    request.headers.set('Authorization', 'Bearer ' + application.$storage.get('session.token'));
    if(application.$storage.get('selectedProjectId')){
        request.headers.set('project', application.$storage.get('selectedProjectId'));
    }
    if(application.$storage.get('session.loginType')){
      request.headers.set('LoginType', application.$storage.get('session.loginType'));
    }
    request.credentials = true;
    next();
});

export default new Vue({
    i18n,
    vuetify,
    router,
    store,
    render: (h) => h(App),
    created() {
      let authErrorMessages = false; //ajax request'lerden birden çok hatalı istek gelebilir, sadece bir tanesi için hata mesajı gönderilmesi için kullanılır.
      this.$eventBus.$on('login', () => { //rest.js deki hata durumlarında login sayfasına yönlendirmek için eklendi.
        if(authErrorMessages){
          return;
        }
        utility.showErrorToast(
          application.$strings.get().login.usernameOrPasswordInvalid,
          application
        );
        setTimeout(() => {
          this.$storage.clear();
          this.$store.dispatch("app/unselectProject");
          this.$router.push("/login")
          authErrorMessages = false;
        }, 1500)
        authErrorMessages = true;
    });

    /*
    this.$eventBus.$on('unexpected', () => { //rest.js deki 500 hata durumlarında UnexpectedPage sayfasına yönlendirmek için eklendi.
      if(authErrorMessages){
        return;
      }
      setTimeout(() => {
        this.$storage.clear();
        this.$router.push({ name: "unexpected" });
        authErrorMessages = false;
      }, 1500)
      authErrorMessages = true;
    });
    */

    this.$eventBus.$on('session', () => { //session time out hatası
      if(authErrorMessages){
        return;
      }
      utility.showErrorToast(
        application.$strings.get().login.sessionTimeOut,
        application
      );
      setTimeout(() => {
        this.$storage.clear();
        this.$store.dispatch("app/unselectProject");
        this.$router.push("/login")
        authErrorMessages = false;
      }, 1500)
      authErrorMessages = true;
  });
    }
}).$mount('#app')
