<template>
  <v-app>
    <!-- Layout component -->
    <component :is="currentLayout" v-if="isRouterLoaded">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </component>

    <v-snackbar v-model="toast.show" :timeout="toast.timeout" :color="toast.color" bottom>
      {{ toast.message }}
      <v-btn v-if="toast.timeout === 0" color="white" text @click="toast.show = false">{{ $t('common.close') }}</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import config from './configs'

// Layouts
import defaultLayout from './layouts/DefaultLayout'
import simpleLayout from './layouts/SimpleLayout'
import landingLayout from './layouts/LandingLayout'
import authLayout from './layouts/AuthLayout'
import errorLayout from './layouts/ErrorLayout'
import widgetLayout from './layouts/WidgetLayout'

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/
export default {
  name: 'App',
  components: {
    defaultLayout,
    simpleLayout,
    landingLayout,
    authLayout,
    errorLayout,
    widgetLayout
  },
  computed: {
    ...mapState('app', ['toast']),
    isRouterLoaded: function() {
      if (this.$route.name !== null) return true

      return false
    },
    currentLayout: function() {
      const layout = this.$route.meta.layout || 'default'

      return layout + 'Layout'
    }
  },
  head: {
   /* script: [
      ...config.jsfiles.map((src) => ({ type: 'text/javascript', src }))
    ],*/
   
    /* script: [
      ...config.pivotfiles.map((src) => ({ type: 'text/javascript', src }))
    ],*/
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
    
  },
   methods: {
      ...mapActions('app', ['checkIfUserIsAdmin']),
    },
  created() {
      this.sessionToken = this.$storage.get('session.token');
      this.$i18n.locale = this.$storage.get("user.language");//FORSP-2080 Dil seçimi değişip sayfa refresh edildiği zaman dil seçeneği tekrar eski haline geliyordu. Bu satırı ekleyince düzeldi.
      if(this.sessionToken) {
        this.checkIfUserIsAdmin({vm: this});
      } 
    },
   
    data() {
      return {
        widgetReady: false,
        widgets: undefined
      }
    }
}
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

