import config from './../configs/app/config';

export default {
    install: function(Vue) {
        const storage = window[config.storageName];

        Vue.prototype.$storage = {
            get(key) {
                return storage.getItem(key);
            },
            set(key, data) {
                storage.setItem(key, data);
            },
            remove(key) {
                storage.removeItem(key);
            },
            clear() {
                storage.clear();
            }
        };
    }
};