var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "shortkey",
          rawName: "v-shortkey",
          value: ["ctrl", "/"],
          expression: "['ctrl', '/']",
        },
      ],
      staticClass: "d-flex flex-grow-1",
      on: { shortkey: _vm.onKeyup },
    },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "elevation-1",
          attrs: {
            app: "",
            floating: "",
            right: _vm.$vuetify.rtl,
            light: _vm.menuTheme === "light",
            dark: _vm.menuTheme === "dark",
            "data-test-id": "wid-lay-nav",
          },
          scopedSlots: _vm._u([
            {
              key: "prepend",
              fn: function () {
                return [
                  _c("div", { staticClass: "pa-2" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "title font-weight-bold text-uppercase white--text",
                        staticStyle: { cursor: "pointer" },
                        attrs: { "data-test-id": "wid-lay-projects" },
                        on: { click: _vm.navigateToProjects },
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.product.name) + " "),
                        _c("img", {
                          staticStyle: { width: "18px" },
                          attrs: {
                            src: require("../assets/images/foresight-lines.png"),
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "overline grey--text",
                        attrs: { "data-test-id": "wid-lay-title" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("menu.title")) + " ")]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "append",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "pa-1 text-center" },
                    _vm._l(_vm.navigation.footer, function (item, index) {
                      return _c(
                        "v-btn",
                        {
                          key: index,
                          attrs: {
                            href: item.href,
                            target: item.target,
                            small: "",
                            text: "",
                            "data-test-id": "wid-lay-nav-btn-" + index,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.key ? _vm.$t(item.key) : item.text) +
                              " "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                padding: "15px",
                fontweight: "bold",
                fontsize: "14px",
                cursor: "pointer",
              },
              attrs: { "data-test-id": "wid-lay-home" },
              on: { click: _vm.navigateToProjects },
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "pr-1",
                  attrs: { "data-test-id": "wid-lay-home-icon" },
                },
                [_vm._v("mdi-home")]
              ),
              _c(
                "span",
                {
                  staticStyle: { "margin-top": "5px" },
                  attrs: { "data-test-id": "wid-lay-home-title" },
                },
                [_vm._v(_vm._s(_vm.$t("menu.home")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "pa-1 font-weight-bold blue--text",
              attrs: { "data-test-id": "wid-lay-apps" },
            },
            [
              _c("apps-menu"),
              _c(
                "span",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { "data-test-id": "wid-lay-sel-project" },
                  on: {
                    click: function ($event) {
                      return _vm.navigateTo(_vm.selectedProject)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.selectedProject.id
                          ? _vm.selectedProject.title
                          : _vm.$t("project.projects")
                      )
                  ),
                ]
              ),
            ],
            1
          ),
          _c("main-menu", { attrs: { menu: _vm.navigation.menu } }),
        ],
        1
      ),
      _c(
        "v-app-bar",
        {
          staticClass: "appbar",
          attrs: {
            app: "",
            color: _vm.isToolbarDetached ? "surface" : undefined,
            flat: _vm.isToolbarDetached,
            light: _vm.toolbarTheme === "light",
            dark: _vm.toolbarTheme === "dark",
            "data-test-id": "wid-lay-app-bar",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "flex-grow-1 d-flex",
              class: [_vm.isToolbarDetached ? "pa-1 mt-3 mx-1" : "pa-0 ma-0"],
              attrs: {
                flat: !_vm.isToolbarDetached,
                "data-test-id": "wid-lay-app-bar-card",
              },
            },
            [
              _c("div", { staticClass: "d-flex flex-grow-1 align-center" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-grow-1 align-center" },
                  [
                    _c("v-app-bar-nav-icon", {
                      staticClass: "iconStyle",
                      attrs: { "data-test-id": "wid-lay-app-bar-icon" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickedNavBar.apply(null, arguments)
                        },
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-grow-1" },
                      [
                        _c("v-breadcrumbs", {
                          attrs: {
                            items: this.bcItems,
                            "data-test-id": "wid-lay-breadcrumb",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-breadcrumbs-item",
                                    {
                                      staticClass: "breadcrumb",
                                      attrs: {
                                        "data-test-id":
                                          "wid-lay-breadcrumb-item-" +
                                          _vm.bcItems.indexOf(item),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickedBreadCrumb(item)
                                        },
                                      },
                                    },
                                    [
                                      item.icon
                                        ? _c(
                                            "v-icon",
                                            { attrs: { size: "20" } },
                                            [_vm._v(_vm._s(item.icon))]
                                          )
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(item.title) + " "),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c("v-autocomplete", {
                      staticClass: "searchField",
                      attrs: {
                        "data-test-id": "wid-lay-search-field",
                        items: _vm.items,
                        "no-filter": "",
                        "search-input": _vm.search,
                        color: "white",
                        "hide-selected": "",
                        "item-text": "name",
                        "item-value": "name",
                        placeholder: _vm.$t("menu.search"),
                        "prepend-inner-icon": "mdi-magnify",
                        "return-object": "",
                        clearable: true,
                      },
                      on: {
                        "update:searchInput": function ($event) {
                          _vm.search = $event
                        },
                        "update:search-input": function ($event) {
                          _vm.search = $event
                        },
                        change: _vm.navigateToPage,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-data",
                          fn: function () {
                            return [
                              _c(
                                "p",
                                {
                                  staticClass: "pt-2",
                                  staticStyle: { color: "gray" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("menu.noResultsFound")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "item",
                          fn: function (data) {
                            return [
                              [
                                data.item.icon
                                  ? _c(
                                      "v-list-item-avatar",
                                      [_c("v-icon", [_vm._v("mdi-magnify")])],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        " " + _vm._s(data.item.name) + " "
                                      ),
                                    ]),
                                    _c("v-divider"),
                                  ],
                                  1
                                ),
                              ],
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selectedItem,
                        callback: function ($$v) {
                          _vm.selectedItem = $$v
                        },
                        expression: "selectedItem",
                      },
                    }),
                    _c("v-spacer", { staticClass: "d-none d-lg-block" }),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", color: "blue" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "iconStyle",
                                      attrs: {
                                        "data-test-id":
                                          "wid-lay-open-filter-toolbar",
                                        icon: "",
                                      },
                                      on: { click: _vm.openFiltersToolBar },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-filter-outline"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("widgetToolbar.filters"))),
                        ]),
                      ]
                    ),
                    _c("v-divider", {
                      staticClass: "mx-1",
                      attrs: { inset: "", vertical: "" },
                    }),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", color: "blue" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _vm.controlCreateChartPermission ||
                                _vm.controlEditChartPermission
                                  ? _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "iconStyle",
                                          attrs: {
                                            "data-test-id":
                                              "wid-lay-panel-save-btn",
                                            icon: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.emit("panel.save")
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-content-save"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("common.save"))),
                        ]),
                      ]
                    ),
                    _vm.controlCreateChartPermission
                      ? _c("v-divider", {
                          staticClass: "mx-1",
                          attrs: { inset: "", vertical: "" },
                        })
                      : _vm._e(),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", color: "blue" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _vm.controlCreateChartPermission
                                  ? _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            "data-test-id":
                                              "wid-lay-add-widget",
                                            dark: "",
                                            fab: "",
                                            right: "",
                                            small: "",
                                            color: "blue",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.emit(
                                                "panel.add-widget"
                                              )
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("mdi-plus")])],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("widgetToolbar.widgetCreate"))
                          ),
                        ]),
                      ]
                    ),
                    _vm.controlCreateChartPermission
                      ? _c("v-divider", {
                          staticClass: "mx-1",
                          attrs: { inset: "", vertical: "" },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mr-1" },
                      [_c("toolbar-notifications")],
                      1
                    ),
                    _c("toolbar-user"),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "pt-3 pr-1" },
        [
          _vm.isFilterOpened
            ? _c(
                "v-container",
                { staticClass: "filterStyle", attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [_c("toolbar-filter")],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-container",
            { staticClass: "fill-height" },
            [_c("v-layout", [_vm._t("default")], 2)],
            1
          ),
          _c(
            "v-footer",
            { staticClass: "footerbar", attrs: { app: "", inset: "" } },
            [
              _c("v-spacer"),
              _c("div", { staticClass: "overline" }, [
                _vm._v("Foresight © " + _vm._s(new Date().getFullYear())),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }