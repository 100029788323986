import tr from "./../translations/tr"
import en from "./../translations/en"
import Vue from 'vue'
let application = new Vue();
export default {
  install: function(Vue) {
    let language = "tr";
    Vue.prototype.$strings = {
      get() {
        return language ==="en" ? en : tr;
      },
      set(value) {
        language = value;
      }
    }
    
    }
};
