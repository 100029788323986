process.env.HOST = 'analitik.gsb.gov.tr';
process.env.PORT = '80'

module.exports = {
  server: {
    url: 'https://fsintelligence.gsb.gov.tr'
  },
  homeUrl: 'http://analitik.gsb.gov.tr',
  storageName: 'localStorage',
  showUserManagement: true,
  showGroupSelection: true,
  showPanelRuleButton: false,
  useTokenLogin: false,
  enableLog: false,
  logoPath: 'foresight.png',
  showLogoAsInfo: false,
  showEGovernmentLogin: false,
  showApplicationLogin: true,
  loginTypes: ['ldap', 'applicationLogin'],
  defaultGroups: [{groupName: 'ForesightReadOnlyUsers'}]
};