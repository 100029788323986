import tr from "./../translations/tr"
export default {
    // main navigation - side menu
    menu: [
        /*{
            text: 'HAKKINDA',
            items: [
                { icon: 'mdi-information-outline', text: 'Hakkında', link: '/landing' }
            ]
        }*/

    ]


}