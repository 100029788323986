process.env.HOST = 'localhost';
process.env.PORT = '8080'

module.exports = {
  server: {
    url: 'https://localhost:8000'
  },
  homeUrl: 'http://localhost:8080',
  storageName: 'sessionStorage',
  showUserManagement: true,
  showGroupSelection: false,
  showPanelRuleButton: true,
  useTokenLogin: true,
  enableLog: true,
  loginTypes: ['ekoopLogin', 'applicationLogin'],
  logoPath: 'tkkmb.png',
  showLogoAsInfo: true
};
