var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        "margin-top": "0px",
        "margin-bottom": "0px",
        padding: "0px",
      },
      attrs: { "data-test-id": "filter-item-" + _vm.index },
    },
    [
      _vm.filter.type === "multi-select"
        ? _c("multi-select-combo-box", {
            attrs: {
              label: _vm.filter.label || _vm.filter.column.title,
              index: _vm.index,
              active: _vm.openedIndex === _vm.index,
              values: [
                ..._vm.filter.appliedValues,
                ..._vm.filter.unappliedValues,
              ],
              possibleValues: _vm.filter.possibleValues || [],
              themed: true,
              disabled: _vm.filtersDisabled,
            },
            on: {
              "index-changed": _vm.filterIndexChanged,
              "values-changed": _vm.setFilterValues,
            },
          })
        : _vm.filter.type === "single-select"
        ? _c("single-select-combo-box", {
            attrs: {
              label: _vm.filter.label || _vm.filter.column.title,
              index: _vm.index,
              active: _vm.openedIndex === _vm.index,
              value:
                _vm.filter.appliedValues[0] || _vm.filter.unappliedValues[0],
              possibleValues: _vm.filter.possibleValues,
              themed: true,
              disabled: _vm.filtersDisabled,
            },
            on: {
              "index-changed": _vm.filterIndexChanged,
              "value-changed": _vm.setFilterValues,
            },
          })
        : _vm.filter.type === "date"
        ? _c("single-date-picker", {
            attrs: {
              value: _vm.getSingleDateValues(_vm.filter),
              index: _vm.index,
              label: _vm.filter.label || _vm.filter.column.title,
            },
            on: { "value-changed": _vm.singleDateFilterChanged },
          })
        : _vm.filter.type === "date-range"
        ? _c("multi-date-picker", {
            attrs: {
              dates: _vm.filter.appliedValues,
              index: _vm.index,
              label: _vm.filter.label || _vm.filter.column.title,
            },
            on: { "value-changed": _vm.dateFilterChanged },
          })
        : _vm.filter.type === "preset-date"
        ? _c("preset-date-picker", {
            attrs: {
              filter: _vm.filter.values || [],
              index: _vm.index,
              label: _vm.filter.label || _vm.filter.column.title,
            },
            on: { "value-changed": _vm.presetDateFilterChanged },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }