process.env.HOST = 'localhost';
process.env.PORT = '8080'

module.exports = {
  server: {
    url: 'http://foresight.turksat.com.tr:8080'
  },
  homeUrl: 'http://foresight.turksat.com.tr',
  storageName: 'localStorage',
  showUserManagement: true,
  showGroupSelection: true,
  showPanelRuleButton: false,
  useTokenLogin: false,
  enableLog: false,
  loginTypes: null,
  logoPath: 'foresight.png',
  showLogoAsInfo: false,
  showEGovernmentLogin: false,
  showApplicationLogin: true
};
