process.env.HOST = 'api.foresight.com.tr';
process.env.PORT = '80';

const HOME_URL = process.env.HOME_URL || "demo.foresight.com.tr";
const API_URL = process.env.API_URL || "api.foresight.com.tr";

//const HOME_URL = process.env.HOME_URL || "localhost:8080";
//const API_URL = process.env.API_URL || "localhost:8000";

module.exports = {
  server: {
    url: 'http://'+API_URL
  },
  homeUrl: 'http://'+HOME_URL,
  // eGovernmentLoginURLOptions: {
  //   url: 'https://giris.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController?response_type=code&state=random_value&scope=Kimlik-Dogrula',
  //   clientId: '&client_id=c2f3b979-a709-4780-89c1-7dbd3817ddc3',
  //   redirectURI: `&redirect_uri=${encodeURIComponent('http://localhost/#/panels')}`
  // },
  storageName: 'localStorage',
  showUserManagement: true,
  showGroupSelection: true,
  showPanelRuleButton: false,
  useTokenLogin: false,
  enableLog: false,
  logoPath: 'foresight.png',
  showLogoAsInfo: false,
  showEGovernmentLogin: false,
  showApplicationLogin: true,
  loginTypes: ['applicationLogin']
  // queryTokenParameterName: 'code',
  // customerLogoName: 'cbddoLogo',
  // customerText: 'TÜRKİYE CUMHURİYETİ CUMHURBAŞKANLIĞI Dijital Dönüşüm Ofisi',
  // customerSubtext: 'Kurum Portallerinde Sunulan Hizmetlerin Kullanım Sayılarının Analizi',
};
