process.env.HOST = 'localhost';
process.env.PORT = '8080'

module.exports = {
    server: {
        url: 'http://localhost:8000'
    },
    homeUrl: 'http://localhost:8080',
    keycloak: {
        init: {
            // Use 'login-required' to always require authentication
            // If using 'login-required', there is no need for the router guards in router.js
            checkLoginIframe: false,
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
          },
        config: {
            url: "https://sso.ygd.turksat.com.tr/auth",
            clientId: "foresight-frontend",
            realm: "ldap-realm",
            redirectUrl: "https://foresight-dev.ygd.turksat.com.tr/"
        }
    },
    // eGovernmentLoginURLOptions: {
    //   url: 'https://giris.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController?response_type=code&state=random_value&scope=Kimlik-Dogrula',
    //   clientId: '&client_id=c2f3b979-a709-4780-89c1-7dbd3817ddc3',
    //   redirectURI: `&redirect_uri=${encodeURIComponent('http://localhost/#/panels')}`
    // },
    storageName: 'localStorage',
    showUserManagement: true,
    showGroupSelection: true,
    showPanelRuleButton: false,
    useTokenLogin: false,
    enableLog: false,
    logoPath: 'foresight.png',
    showLogoAsInfo: false,
    showEGovernmentLogin: false,
    showApplicationLogin: true,
    loginTypes: ['oidc', 'applicationLogin']
        // defaultGroups: [{ groupName: 'ForesightReadOnlyUsers' }, { groupName: 'ForesightAdmins' }, { groupName: 'demo' }]
        // queryTokenParameterName: 'code',
        // customerLogoName: 'cbddoLogo',
        // customerText: 'TÜRKİYE CUMHURİYETİ CUMHURBAŞKANLIĞI Dijital Dönüşüm Ofisi',
        // customerSubtext: 'Kurum Portallerinde Sunulan Hizmetlerin Kullanım Sayılarının Analizi',
};
