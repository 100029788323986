var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.menuItem.items
        ? _c(
            "v-list-item",
            {
              attrs: {
                "input-value": _vm.menuItem.value,
                to: _vm.menuItem.link,
                exact: _vm.menuItem.exact,
                disabled: _vm.menuItem.disabled,
                "active-class": "primary--text",
                link: "",
              },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-icon",
                    {
                      class: { "grey--text": _vm.menuItem.disabled },
                      attrs: { small: _vm.small },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.menuItem.icon || "mdi-circle-medium") +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.menuItem.key
                            ? _vm.$t(_vm.menuItem.key)
                            : _vm.menuItem.text
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-list-group",
            {
              attrs: {
                value: _vm.menuItem.regex
                  ? _vm.menuItem.regex.test(_vm.$route.path)
                  : false,
                disabled: _vm.menuItem.disabled,
                "sub-group": _vm.subgroup,
                to: _vm.menuItem.link,
                link: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function () {
                    return [
                      !_vm.subgroup
                        ? _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { small: _vm.small } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.menuItem.icon || "mdi-circle-medium"
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.menuItem.key
                                    ? _vm.$t(_vm.menuItem.key)
                                    : _vm.menuItem.text
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_vm._t("default")],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }