<template>
  <v-container :data-test-id="'container-' + index" fluid>
    <v-autocomplete
      style="
        height: 20px;
        padding-top: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
      "
      v-model="filterValues"
      :items="getPossibleValues"
      item-text="title"
      item-value="value"
      dense
      :label="label"
      multiple
      @change="valuesChanged"
      :clearable="true"
      @click:clear="clearData"
      :data-test-id="'v-list-' + index"
    >

      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">{{ item.title }}</span>
        <span v-if="index === 1" class="grey--text caption pl-1"
          >(+{{ filterValues.length - 1 }})</span
        >
      </template>
      <template #item="data">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-layout wrap v-on="on" v-bind="attrs">
                <v-list-item-action>
                  <v-checkbox v-model="data.attrs.inputValue"/>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{data.item.title}}</v-list-item-title>
                </v-list-item-content>
              </v-layout>
            </template>
            <span>{{ data.item.value }}</span>
          </v-tooltip>
        </template> 
    </v-autocomplete>
  </v-container>
</template>

<script>
import utilities from "./../../utilities/utilities";
import { mapState, mapGetters } from "vuex";
export default {
  props: [
    "label",
    "values",
    "index",
    "active",
    "possibleValues",
    "nameField",
    "disabled"
  ],
  data() {
    return {
      isActive: false,
      filter: "",
      filterValues: []
    };
  },
  computed: {
    ...mapState("app", {
      filtersDisabled: "filtersDisabled",
      filters: "filters"
    }),
    ...mapGetters("app", ["getFilter"]),

    selectAllItems() {
      return this.filterValues.length === (this.possibleValues || []).length; //metot ismi
    },
    selectSomeItems() {
      return this.filterValues.length > 0 && !this.selectAllItems;
    },
    icon() {
      if (this.selectAllItems) return "mdi-close-box";
      if (this.selectSomeItems) return "mdi-minus-box";

      return "mdi-checkbox-blank-outline";
    },
    getPossibleValues() {
      let values = this.possibleValues.filter(
        value => value !== null && value !== undefined
      );
      const computedValues = (values || []).map(value => {
        if (value.length > 35)
          return { title: value.substring(0, 35).concat(" ..."), value: value };
        else return { title: value, value: value };
      });
      return computedValues;
    }
  },
  watch: {
    values: function(newVal, oldVal) {
      this.filterValues = newVal || [];
    }
  },
  created() {
    this.filterValues = this.values || [];
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllItems) {
          this.filterValues = [];
          this.valuesChanged();
        } else {
          this.filterValues = this.possibleValues.slice();
          this.valuesChanged();
        }
      });
    },
    valuesChanged() {
      this.$emit("values-changed", this.filterValues);
    },

    emit(event) {
      this.$eventBus.$emit(event);
    },
    clearData() {
      this.filterValues = [];
      this.$emit("value-changed", this.filterValues);
    }
  }
};
</script>
<style scoped>
.container {
  padding-left: 0px;
  padding-right: 10px;
  max-width: 350px;
  min-width: 200px;
  z-index: 99999;
}
</style>
