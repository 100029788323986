<template>
  <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
    <v-sheet
      class="layout-side mx-auto mx-md-0 d-none d-md-flex flex-md-column justify-space-between px-0"
      style="background-color:#243852;"
    >
      <div class="mt-3 mt-md-10 pa-2">
        <div class="display-2 font-weight-bold primary--text">
          <img
            data-test-id="analitik-img"
            class="w-auto"
            src="./../assets/images/analitik.png"
            style="width: 50%;"
          />
        </div>
        <div data-test-id="auth-title" class="title my-2">
          {{ $t("menu.title") }}
        </div>
      </div>
      <div>
        <video
          width="450"
          poster="./../assets/images/grafik.png"
          autoplay
          loop
          muted
        >
          <source type="video/webm" src="./../assets/images/anim.webm" />
        </video>
      </div>
    </v-sheet>

    <div
      class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column"
    >
      <div data-test-id="auth-content" class="layout-content ma-auto w-full">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("app", ["product"])
  }
};
</script>

<style scoped>
.layout-side {
  width: 450px;
}

.layout-content {
  max-width: 480px;
}
</style>
