var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-test-id": "container-" + _vm.index } },
    [
      _c("v-autocomplete", {
        attrs: {
          items: _vm.getFilters,
          "item-text": "title",
          "item-value": "value",
          dense: "",
          label: _vm.label,
          clearable: true,
          "data-test-id": "date-list-" + _vm.index,
        },
        on: { change: _vm.valueChanged, "click:clear": _vm.clearData },
        model: {
          value: _vm.presetFilter[0],
          callback: function ($$v) {
            _vm.$set(_vm.presetFilter, 0, $$v)
          },
          expression: "presetFilter[0]",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }