import routeMapData from "./geo-data";

export default {
    urlBuilder,
    difference,
    differenceCaseInsensitive,
    generateUUID,
    clone,
    showErrorToast,
    showInfoToast,
    showSuccessToast,
    roundBy,
    groupAsMap,
    applyToAllTemplateFields,
    setVisualOptionsValue,
    getVisualOptionsValue,
    getRandomMapData,
    getRandomRouteMap,
    blendColors,
    format,
    buildWidgetStyle,
    formatString
};

import './geo-data';

function buildWidgetStyle(options, hexToRgba) {
  if (!options.widgetBorder) {
    options.widgetBorder = {
      width: options.widgetBorderWidth,
      color: options.widgetBorderColor,
      radius: options.widgetBorderRadius
    }
  }

  const topBorderWidth = parseInt(options.widgetBorder.top || options.widgetBorder.top === 0 ? options.widgetBorder.top : options.widgetBorder.width || 0);
  const bottomBorderWidth = parseInt(options.widgetBorder.bottom || options.widgetBorder.bottom === 0 ? options.widgetBorder.bottom : options.widgetBorder.width || 0);
  const leftBorderWidth = parseInt(options.widgetBorder.left || options.widgetBorder.left === 0 ? options.widgetBorder.left : options.widgetBorder.width || 0);
  const rightBorderWidth = parseInt(options.widgetBorder.right || options.widgetBorder.right === 0 ? options.widgetBorder.right : options.widgetBorder.width || 0);

  const color = options.widgetBorder.color;
  const type = options.widgetBorder.type;

  const borderWidth = `${topBorderWidth || 1}px ${rightBorderWidth || 1}px ${bottomBorderWidth || 1}px ${leftBorderWidth || 1}px`;
  const borderColor = `${topBorderWidth ? color : 'transparent'} ${rightBorderWidth ? color : 'transparent'} ${bottomBorderWidth ? color : 'transparent'} ${leftBorderWidth ? color : 'transparent'}`;
  const borderStyle = `${topBorderWidth ? type : 'dotted'} ${rightBorderWidth ? type : 'dotted'} ${bottomBorderWidth ? type : 'dotted'} ${leftBorderWidth ? type : 'dotted'}`;

  //table chart tipi için boyut ayarlarına padding eklendi.
  let paddingLeft = 0;
  let paddingRight = 0;
  let paddingTop = 0;
  let paddingBottom = 0;
  if(options.padding){
    paddingLeft = parseInt(options.padding.left);
    paddingRight = parseInt(options.padding.right);
    paddingTop = parseInt(options.padding.top);
    paddingBottom = parseInt(options.padding.bottom);
  }


  let backgroundColor = '';
  if (options.widgetBackgroundColor) {
    backgroundColor = hexToRgba(options.widgetBackgroundColor, parseFloat(options.backgroundColorOpacity));
  }
  return {
    backgroundColor: backgroundColor,
    'border-style': borderStyle || 'solid',
    'border-color': borderColor,
    'border-width': borderWidth,
    'border-radius': (options.widgetBorder.radius || 0) + 'px',
    'padding-left' : paddingLeft + 'px',
    'padding-right' : paddingRight + 'px',
    'padding-top' : paddingTop + 'px',
    'padding-bottom' : paddingBottom + 'px'

  };
}

function format(format, number) {
  const locale = d3.formatLocale({
    decimal: ',',
    thousands: '.',
    grouping: [3],
    currency: '₺',
    percent: '%'
  });

  return locale.format(format)(number);
}

/**
 * @param options {
 *    model,
 *    id,
 *    query,
 *    populateOptions,
 *    params
 * }
 * @returns {string}
 */
function urlBuilder(options) {
  let url = '';

  if (options.model) {
    url += '/' + options.model;
  }

  if (options.id) {
    url += '/' + options.id;
  }

  if (options.query) {
    url += '/query/' + options.query.replace(/\//g, '%2F');
  }

  if (options.query2) {
      url += '/query2/' + options.query2;
  }

  if (options.populateOptions) {
    url += '/' + options.populateOptions.replace(/{/g, '%7B').replace(/}/g, '%7D');
  }

  if (options.params && Array.isArray(options.params) && options.params.length > 0) {
    url += '/' + options.params.map(_param => _param.toString().replace(/#/g, '%23')).join('/');
  }

  return url;
}


function difference(minuend, subtrahend, comparisonField) {
  let difference = [];

  minuend.forEach(minuendValue => {
    let found = false;
    subtrahend.forEach(subtValue => {
      if (subtValue[comparisonField] === minuendValue[comparisonField]) {
        found = true;
      }
    });

    if (!found) {
      difference.push(minuendValue);
    }
  });

  return difference;
}

function differenceCaseInsensitive(minuend, subtrahend, comparisonField) {
  let difference = [];

  minuend.map(minuendValue => {
    let found = false;
    subtrahend.map(subtValue => {
      if (subtValue[comparisonField].toUpperCase() === minuendValue[comparisonField].toUpperCase()) {
        found = true;
      }
    });

    if (!found) {
      difference.push(minuendValue);
    }
  });

  return difference;
}


function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function formatString(formatted, params) {
    for( var param in params ) {
        formatted = formatted.replace("{" + param + "}", params[param]);
    }
    return formatted;
};

function clone(object) {
  return JSON.parse(JSON.stringify(object));
}
//aysegul:yeni toast ile güncellendi.
function showSuccessToast(message, vue) {
  vue.$toast.success(message, {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
    toastClass: {zIndex:9999999}
  });
}

function showErrorToast(message, vue) {
    vue.$toast.error(message, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
        toastClass: {zIndex:9999999}
    });
}

function showInfoToast(message, vue) {
  vue.$toast.info(message, {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
    toastClass: {zIndex:9999999}
  });
}


function roundBy(val, roundNum) {
  return Math.round((val * roundNum) / Math.round(roundNum)) * Math.round(val);
}

function groupAsMap(data, key) {
  return data.reduce(function (map, datum) {
    if (!map.has(datum[key])) {
      map.set(datum[key], []);
    }

    map.get(datum[key]).push(datum);

    return map;
  }, new Map());
}

function applyToAllTemplateFields(parentOption, callback) {
  if (Array.isArray(parentOption)) {
    parentOption.forEach(childOption => {
      this.applyToAllTemplateFields(childOption, callback);
    });
  } else if (parentOption.childrenOptions) {
    this.applyToAllTemplateFields(parentOption.childrenOptions, callback);
  } else if (parentOption.children) {
    parentOption.children.forEach(child => {
      callback(child);
    });
  }
}

function setVisualOptionsValue(object, keys, value) {
  const key = keys.shift();

  if (keys.length > 0) {
    if (!object[key]) {
      object[key] = {};
    }
    this.setVisualOptionsValue(object[key], keys, value);
  } else {
    if (Array.isArray(value)) {
      if(value.length>0 && value[0].name){//lejant renk ve isimleri için eklendi.
        object[key] = value;
      }
      else {
        value = value.map(value => value.value);
        object[key] = value;      
       }
    }
    else
       object[key] = value;
  }
}

function getVisualOptionsValue(object, keys) {
  const key = keys.shift();

  if (keys.length > 0) {
    if (object[key]) {
      return this.getVisualOptionsValue(object[key], keys);
    }
  } else {
    if (Array.isArray(object[key])) {
      if(object[key].length>0 && object[key][0].name){//lejant renk ve isimleri için eklendi.
        return object[key];
      }
      else
        return object[key].map(item => {
          return {
            value: item
          }
        });
    } else {
      return object[key];
    }
  }
}

function getRandomMapData() {
  return {
    type: 'coloredMap',
    visualMap: {
      left: 'right',
      itemWidth: 0,
      itemHeight: 0,
      text: ['', ''],
      calculable: false,
      visible: false,
      color: [
        "#0288D1",
        "#81D4FA",
        "#e0ffff"
      ]
    },
    dataset: {
      dimensions: ['name', 'value'],
      source: [{
          value: Math.round(Math.random() * 200),
          name: 'Adana'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Adıyaman'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Afyonkarahisar'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Ağrı'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Amasya'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Ankara'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Antalya'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Artvin'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Aydın'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Balıkesir'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Bilecik'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Bingöl'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Bitlis'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Bolu'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Burdur'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Bursa'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Çanakkale'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Çankırı'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Çorum'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Denizli'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Diyarbakır'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Edirne'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Elazığ'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Erzincan'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Erzurum'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Eskişehir'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Gaziantep'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Giresun'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Gümüşhane'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Hakkari'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Hatay'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Isparta'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Mersin'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'İstanbul'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'İzmir'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Kars'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Kastamonu'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Kayseri'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Kırklareli'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Kırşehir'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Kocaeli'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Konya'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Kütahya'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Malatya'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Manisa'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Kahramanmaraş'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Mardin'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Muğla'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Muş'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Nevşehir'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Niğde'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Ordu'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Rize'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Sakarya'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Samsun'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Siirt'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Sinop'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Sivas'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Tekirdağ'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Tokat'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Trabzon'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Tunceli'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Şanlıurfa'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Uşak'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Van'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Yozgat'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Zonguldak'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Aksaray'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Bayburt'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Karaman'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Kırıkkale'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Batman'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Şırnak'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Bartın'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Ardahan'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Iğdır'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Yalova'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Karabük'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Kilis'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Osmaniye'
        },
        {
          value: Math.round(Math.random() * 200),
          name: 'Düzce'
        }
      ]
    },
    series: [{
      type: 'map',
      roam: false,
      map: 'turkey',
      /*itemStyle: {
        emphasis: {label: {show: true}}
      }*/
    }]
  };
}

function getRandomRouteMap(visualOptions) {
  const options = {
    type: 'routeMap',
    visualMap: {
      left: 'right',
      itemWidth: 0,
      itemHeight: 0,
      text: ['', ''],
      calculable: false,
      visible: false,
      color: [
        "#0288D1",
        "#81D4FA",
        "#e0ffff"
      ]
    },
    geo: {
      map: 'europe',
      label: {
        emphasis: {
          show: false
        }
      },
      roam: false
    },
    series: [{
        type: 'map',
        map: 'europe',
        geoIndex: 1,
        zlevel: 2,
        data: routeMapData.mapData
      },
      {
        type: 'lines',
        zlevel: 2,
        symbol: ['none', 'arrow'],
        symbolSize: 10,
        lineStyle: {
          normal: {
            color: '#fe3c3c',
            width: 1,
            opacity: 0.6,
            curveness: 0.2
          }
        },
        data: routeMapData.lineData
      }
    ]
  };

  if (visualOptions) {
    options.series[1].lineStyle.normal.color = visualOptions.geoSeries.routeColor;
    options.series[1].lineStyle.normal.width = visualOptions.geoSeries.routeWidth;
  }

  return options;
}

function blendColors(base, added) {
  added = added.substring(5, added.length - 1).split(',').map(_t => parseFloat(_t));
  base = base.substring(5, base.length - 1).split(',').map(_t => parseFloat(_t));

  const mix = [];
  mix[3] = 1 - (1 - added[3]) * (1 - base[3]); // alpha
  mix[0] = Math.round((added[0] * added[3] / mix[3]) + (base[0] * base[3] * (1 - added[3]) / mix[3])); // red
  mix[1] = Math.round((added[1] * added[3] / mix[3]) + (base[1] * base[3] * (1 - added[3]) / mix[3])); // green
  mix[2] = Math.round((added[2] * added[3] / mix[3]) + (base[2] * base[3] * (1 - added[3]) / mix[3])); // blue

  return `rgba(${mix.join(',')})`;
}
