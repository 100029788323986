<template>
  <div
    :data-test-id="'filter-item-' + index"
    style="margin-top: 0px; margin-bottom: 0px; padding: 0px"
  >
    <multi-select-combo-box
      v-if="filter.type === 'multi-select'"
      @index-changed="filterIndexChanged"
      @values-changed="setFilterValues"
      :label="filter.label || filter.column.title"
      :index="index"
      :active="openedIndex === index"
      :values="[...filter.appliedValues, ...filter.unappliedValues]"
      :possibleValues="filter.possibleValues || []"
      :themed="true"
      :disabled="filtersDisabled"
    ></multi-select-combo-box>
    <single-select-combo-box
      v-else-if="filter.type === 'single-select'"
      @index-changed="filterIndexChanged"
      @value-changed="setFilterValues"
      :label="filter.label || filter.column.title"
      :index="index"
      :active="openedIndex === index"
      :value="filter.appliedValues[0] || filter.unappliedValues[0]"
      :possibleValues="filter.possibleValues"
      :themed="true"
      :disabled="filtersDisabled"
    ></single-select-combo-box>
    <single-date-picker
      v-else-if="filter.type === 'date'"
      :value="getSingleDateValues(filter)"
      :index="index"
      :label="filter.label || filter.column.title"
      @value-changed="singleDateFilterChanged"
    ></single-date-picker>
    <multi-date-picker
      v-else-if="filter.type === 'date-range'"
      :dates="filter.appliedValues"
      :index="index"
      :label="filter.label || filter.column.title"
      @value-changed="dateFilterChanged"
    ></multi-date-picker>
    <preset-date-picker
      v-else-if="filter.type === 'preset-date'"
      :filter="filter.values || []"
      :index="index"
      @value-changed="presetDateFilterChanged"
      :label="filter.label || filter.column.title"
    ></preset-date-picker>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import MultiSelectComboBox from "./../../components/selects/MultiSelectComboBox";
import SingleSelectComboBox from "./../../components/selects/SingleSelectComboBox";
import SingleDatePicker from "./../../components/datepicker/SingleDatePicker";
import MultiDatePicker from "./../../components/datepicker/MultiDatePicker";
import PresetDatePicker from "./../../components/datepicker/PresetDatePicker";
export default {
  components: {
    MultiSelectComboBox,
    SingleSelectComboBox,
    SingleDatePicker,
    MultiDatePicker,
    PresetDatePicker
  },
  props: ["index", "openedIndex"],
  computed: {
    ...mapState("app", {
      filtersDisabled: "filtersDisabled",
      filters: "filters"
    }),
    ...mapGetters("app", ["getFilter"]),
    filter() {
      return this.getFilter(this.index);
    },

    relatedFilters() {
      const filter = this.filters[this.index];

      if (filter.table.id) {
        return [];
      } else {
        return this.filters.filter(
          _filter =>
            _filter.table.name === this.filter.table.name &&
            _filter !== this.filter
        );
      }
    }
  },
  methods: {
    ...mapMutations("app", {
      setValues: "setValues"
    }),
    ...mapActions("app", {
      storeSetFilterValues: "setFilterValues"
    }),
    emit(event) {
      this.$eventBus.$emit(event);
    },
    filterIndexChanged(index) {
      this.$emit("filter-index-changed", index);
    },
    getSingleDateValues(filter) {
      if (filter.unappliedValues && filter.unappliedValues.length > 0)
        return filter.unappliedValues;
      if (filter.appliedValues && filter.appliedValues.length > 0)
        return filter.appliedValues;
      return [];
    },
    setFilterValues(values) {
      if (values.length === this.filter.possibleValues.length) {
        this.setValues({
          filter: this.filter,
          values: values,
          valueType: "unappliedValues"
        });
      } else if (values.length === 0) {
        this.setValues({
          filter: this.filter,
          values: values,
          valueType: "appliedValues"
        });
        this.storeSetFilterValues({ filter: this.filter, values });
      } else {
        this.storeSetFilterValues({ filter: this.filter, values });
      }
    },
    presetDateFilterChanged(changedFilter) {
      let date = [];
      if (changedFilter.data !== null && changedFilter.data[0] !== null)
        date = changedFilter.data;
      this.setValues({
        filter: this.filter,
        values: date,
        valueType: "appliedValues"
      });
    },
    dateFilterChanged(data) {
      this.setValues({
        filter: this.filter,
        values: data,
        valueType: "appliedValues"
      });
    },
    singleDateFilterChanged(data) {
      let date = [];
      date[0] = data;
      this.setValues({
        filter: this.filter,
        values: date,
        valueType: "appliedValues"
      });
      //this.storeSetFilterValues({ filter: this.filter, date });
    }
  },
  data() {
    return {
      oldFilters: []
    };
  }
};
</script>
