<template>
  <v-card
    data-test-id="tf-card1"
    height="70px"
    class="d-flex flex-grow-1 primary darken-4"
  >
    <v-row no-gutters>
      <v-col cols="12" sm="3" md="2" lg="2">
        <!--v-ripple="false" :buttona tıklandığı zaman arka plan rengini değiştirmiyor.  -->
        <v-card
          height="50px"
          class="d-flex flex-grow-1 primary darken-4"
          style="padding-top: 10px"
          data-test-id="tf-card2"
        >
          <v-row no-gutters>
            <v-col cols="12" sm="3" md="3" lg="4">
              <v-btn
                v-if="controlEditPanelFilterPermission"
                icon
                large
                class="primary darken-4 ml-1"
                @click="emit('edit-filters')"
                data-test-id="edit-filter-btn"
              >
                <v-icon data-test-id="edit-filter-icon">mdi-cog-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="4">
              <v-btn
                data-test-id="reset-filter-btn"
                icon
                large
                @click="resetFilters"
                class="primary darken-4"
              >
                <v-icon data-test-id="reset-filter-icon"
                  >mdi-backup-restore</v-icon
                >
              </v-btn>
            </v-col>

            <v-col cols="12" sm="3" md="3" lg="3">
              <v-btn
                data-test-id="apply-filter-btn"
                icon
                large
                class="primary darken-4"
                @click="applyFilters"
              >
                <v-icon data-test-id="apply-filter-icon">mdi-filter</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1">
              <v-divider class="mx-0" vertical></v-divider>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="8" lg="10">
        <div class="box">
          <v-btn
            icon
            large
            class="primary darken-4 mt-1 mr-1"
            @click="swipeLeft"
            data-test-id="left-btn"
          >
            <v-icon x-large data-test-id="left-btn-icon">
              mdi-chevron-left</v-icon
            >
          </v-btn>
          <div class="center" id="content" ref="content">
            <div
              v-for="(filter, index) in filters"
              :key="index"
              class="internal"
            >
              <filter-component
                :opened-index="openedIndex"
                :index="index"
                @filter-index-changed="filterIndexChanged"
              ></filter-component>
            </div>
          </div>
          <v-btn
            icon
            large
            class="primary darken-4 mt-1 mr-2 ml-1"
            @click="swipeRight"
            data-test-id="right-btn"
          >
            <v-icon x-large data-test-id="right-btn-icon">
              mdi-chevron-right</v-icon
            >
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import config from "./../../configs";
import utilities from "./../../utilities/utilities";
import FilterComponent from "./../../pages/dashboard/FilterComponent";
import permissions from "./../../configs/permissions";
export default {
  components: {
    FilterComponent
  },
  data() {
    return {
      panel: {},
      openedIndex: -1,
      refreshInterval: undefined
    };
  },
  computed: {
    ...mapState("app", ["filters", "filtersDisabled", "selectedProject"]),
    controlEditPanelFilterPermission(){
      return this.selectedProject.permissions.includes(permissions.panel.filter);
    }
  },
  methods: {
    ...mapMutations("app", {
      resetStoreFilters: "resetFilters",
      addStoreFilterValue: "addValue"
    }),
    ...mapActions("app", {
      storeApplyFilters: "applyFilters",
      storeSetFilterValues: "setFilterValues",
      storeRemoveFilterValue: "removeFilterValue"
    }),
    emit(event) {
      //if (this.buttonsEnabled) {
      this.$eventBus.$emit(event);
      //}
    },
    filterIndexChanged(index) {
      this.openedIndex = index;
    },
    applyFilters() {
      this.storeApplyFilters();
    },
    resetFilters() {
      this.$eventBus.$emit("reset-widgets");
      this.resetStoreFilters();
      this.$eventBus.$emit("applyFilters");
    },
    setFilterValues(values) {
      this.storeSetFilterValues({
        filter: this.filters[this.openedIndex],
        values
      });
    },

    ///////////////////////////////////////////////////
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft;
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth ||
            scrollPixels < 0)
        )
      ) {
        const startTime =
          "now" in window.performance
            ? performance.now()
            : new Date().getTime();

        function scroll(timestamp) {
          const timeElapsed = timestamp - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
          element.scrollLeft = scrollPos + scrollPixels * progress;
          if (timeElapsed < duration) {
            window.requestAnimationFrame(scroll);
          } else {
            return;
          }
        }
        window.requestAnimationFrame(scroll);
      }
    },
    swipeLeft() {
      const content = this.$refs.content;
      this.scrollTo(content, -700, 800);
    },
    swipeRight() {
      const content = this.$refs.content;
      this.scrollTo(content, 700, 800);
    }
  },
  beforeDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }

    this.$eventBus.$off("panel.loaded");
    this.$eventBus.$off("chart-clicked");
    this.$eventBus.$off("set-header-state");
  },
  created() {
    this.$eventBus.$on("panel.loaded", panel => {
      this.panel = panel;

      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
      }

      if (this.panel.refresh_time && this.panel.refresh_time > 0) {
        this.refreshInterval = setInterval(() => {
          this.$eventBus.$emit("applyFilters");
        }, this.panel.refresh_time * 1000);
      }
    });

    this.$eventBus.$on("chart-clicked", filters => {
      const promises = [];
      let results = [];

      filters.forEach(filter => {
        this.filters.forEach(panelFilter => {
          promises.push(
            this.$rest.post(
              { model: "filter" },
              {
                factId: filter.fact.id,
                factName: filter.fact.name,
                factSchema: filter.fact.schema,
                sourceTable: filter.table,
                sourceColumn: filter.column,
                values: [filter.value],
                targetTable: panelFilter.table.name,
                targetColumn: panelFilter.column.name
              },
              result => {
                results.push(result.body);
              }
            )
          );
        });
      });

      function buildOperations(results, panelFilters) {
        const operations = [];
        results
          .filter(_result => _result && _result.data && _result.data.length > 0)
          .forEach(_result => {
            const result = _result.data;
            const tag = _result.tag;
            if (result && result.length > 0) {
              const foundPanelFilter = panelFilters.find(
                _panelFilter =>
                  _panelFilter.table.name === tag.targetTable &&
                  _panelFilter.column.name === tag.targetColumn
              );

              if (foundPanelFilter) {
                const clickedValues = result.map(_value => _value.target);
                const clickedFilter = filters.find(
                  _filter =>
                    _filter.table === tag.sourceTable &&
                    _filter.column === tag.sourceColumn
                );
                const filterIndex = panelFilters.indexOf(foundPanelFilter);

                foundPanelFilter.values = [
                  ...foundPanelFilter.unappliedValues,
                  ...foundPanelFilter.appliedValues
                ];

                const notExistingValues = clickedValues.filter(
                  _value => !foundPanelFilter.values.includes(_value)
                );

                if (notExistingValues.length === 0) {
                  operations.push({
                    type: "remove",
                    filterIndex,
                    values: clickedValues,
                    clickedFilter
                  });
                } else {
                  operations.push({
                    type: "add",
                    filterIndex,
                    values: notExistingValues,
                    clickedFilter
                  });
                }
              }
            }
          });

        return operations;
      }

      Promise.all(promises).then(() => {
        let panelFilters = utilities.clone(this.filters);

        let operations = buildOperations(results, panelFilters);

        if (operations.some(_operation => _operation.type === "add")) {
          operations = operations.filter(
            _operation => _operation.type === "add"
          );
        }

        operations.forEach(_operation => {
          if (_operation.type === "add") {
            _operation.values.forEach(_value => {
              if (
                this.filters[_operation.filterIndex].type !== "single-select"
              ) {
                this.addStoreFilterValue({
                  filter: this.filters[_operation.filterIndex],
                  value: _value,
                  valueType: _operation.clickedFilter.valueType
                });
              } else {
                this.storeSetFilterValues({
                  filter: this.filters[_operation.filterIndex],
                  values: _value
                });
              }
            });
          } else if (
            _operation.type === "remove" &&
            _operation.clickedFilter.valueType === "unappliedValues" &&
            this.filters[_operation.filterIndex].type !== "single-select"
          ) {
            _operation.values.forEach(_value => {
              this.storeRemoveFilterValue({
                filter: this.filters[_operation.filterIndex],
                value: _value
              });
            });
          }
        });
      });
    });
  }
};
</script>

<style scoped>
.v-btn::before {
  background-color: transparent;
}

.box {
  display: flex;
  align-items: flex-start;
  height: 70px;
  width: 84%;
  flex-direction: row;
  flex-wrap: nowrap;
  position: absolute;
}

.center {
  float: left;
  width: 100%;
  height: 70px;
  overflow: hidden;
  white-space: nowrap;
}
.internal {
  width: auto;
  height: 60px;
  display: inline-block;
  position: relative;
}
</style>
