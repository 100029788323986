import utilities from "./utilities";

const geoCoords = {
  'Almanya': [13.4, 52.51666667],
  'Litvanya': [25.316667, 54.68333333],
  'Slovenya': [14.516667, 46.05],
  'Finlandiya': [24.933333, 60.16666667],
  'Gürcistan': [44.833333, 41.68333333],
  'Rusya': [37.6, 55.75],
  'Belçika': [4.333333, 50.83333333],
  'Bulgaristan': [23.316667, 42.68333333],
  'Hırvatistan': [16, 45.8],
  'Malta': [14.5, 35.88333333],
  'İrlanda': [-6.233333, 53.31666667],
  'San Marino': [12.416667, 43.93333333],
  'Portekiz': [-9.133333, 38.71666667],
  'Romanya': [26.1, 44.43333333],
  'Danimarka': [12.583333, 55.66666667],
  'Lihtenştayn': [9.516667, 47.13333333],
  'Karadağ': [19.266667, 42.43333333],
  'Çekya': [14.466667, 50.08333333],
  'İspanya': [-3.683333, 40.4],
  'Macaristan': [19.083333, 47.5],
  'Avusturya': [16.366667, 48.2],
  'İsveç': [18.05, 59.33333333],
  'Arnavutluk': [19.816667, 41.31666667],
  'Azerbaycan': [49.866667, 40.38333333],
  'Makedonya': [.433333, 42, ],
  'Sırbistan': [20.5, 44.83333333],
  'Kosova': [21.166667, 42.66666667],
  'Monako': [7.416667, 43.73333333],
  'Yunanistan': [23.733333, 37.98333333],
  'Lüksemburg': [6.116667, 49.6],
  'İsviçre': [7.466667, 46.91666667],
  'İzlanda': [-21.95, 64.15],
  'Türkiye': [32.866667, 39.93333333],
  'EstoNamenya': [24.716667, 59.43333333],
  'İtalya': [12.483333, 41.9],
  'LetoNamenya': [24.1, 56.95],
  'Polonya': [21, 52.25],
  'Andorra': [1.516667, 42.5],
  'Ermenistan': [44.5, 40.16666667],
  'Fransa': [2.333333, 48.86666667],
  'Slovakya': [17.116667, 48.15],
  'Norveç': [10.75, 59.91666667],
  'Hollanda': [4.916667, 52.35],
  'Ukrayna': [30.516667, 50.43333333],
  'Özbekistan': [69.25, 41.31666667],
  'Kanada': [-75.69719309, 45.4215296],
  'Japonya': [139.691706, 35.6894875],
  'Beyaz Rusya': [27.566667, 53.9],
  'Birleşik Krallık': [-0.083333, 51.5],
  'Bosna-Hersek': [18.416667, 43.86666667],
  'Kıbrıs Rum Kesimi': [33.366667, 35.16666667],
  'Amerika Birleşik Devletleri': [-77.03687, 38.9071923],
  'Güney Kore': [126.977969, 37.5665351]
};


const geoData = [
  {
    fromName: 'Türkiye',
    toName: 'Almanya',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Litvanya',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Norveç',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Slovenya',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Finlandiya',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Gürcistan',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Rusya',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Belçika',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Bulgaristan',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Hırvatistan',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Malta',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'İrlanda',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Portekiz',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Romanya',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Danimarka',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'İspanya',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Macaristan',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Avusturya',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Arnavutluk',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Azerbaycan',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Ukrayna',
    value: Math.round(Math.random() * 200)
  },
  {
    fromName: 'Türkiye',
    toName: 'Makedonya',
    value: Math.round(Math.random() * 200)
  }
];

const lineData = geoData.map(_dataItem => {
  return {
    coords: [geoCoords[_dataItem.fromName], geoCoords[_dataItem.toName]],
    fromName: _dataItem.fromName,
    toName: _dataItem.toName
  };
});

const mapData = geoData.map(_dataItem => {
  return {
    name: _dataItem.toName,
    value: _dataItem.value
  }
});


export default {
  lineData,
  mapData
}

