var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex text-center flex-column flex-md-row flex-grow-1" },
    [
      _c(
        "v-sheet",
        {
          staticClass:
            "layout-side mx-auto mx-md-0 d-none d-md-flex flex-md-column justify-space-between px-0",
          staticStyle: { "background-color": "#243852" },
        },
        [
          _c("div", { staticClass: "mt-3 mt-md-10 pa-2" }, [
            _c(
              "div",
              { staticClass: "display-2 font-weight-bold primary--text" },
              [
                _c("img", {
                  staticClass: "w-auto",
                  staticStyle: { width: "50%" },
                  attrs: {
                    "data-test-id": "analitik-img",
                    src: require("./../assets/images/analitik.png"),
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "title my-2",
                attrs: { "data-test-id": "auth-title" },
              },
              [_vm._v(" " + _vm._s(_vm.$t("menu.title")) + " ")]
            ),
          ]),
          _c("div", [
            _c(
              "video",
              {
                attrs: {
                  width: "450",
                  poster: require("./../assets/images/grafik.png"),
                  autoplay: "",
                  loop: "",
                  muted: "",
                },
                domProps: { muted: true },
              },
              [
                _c("source", {
                  attrs: {
                    type: "video/webm",
                    src: require("./../assets/images/anim.webm"),
                  },
                }),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column",
        },
        [
          _c(
            "div",
            {
              staticClass: "layout-content ma-auto w-full",
              attrs: { "data-test-id": "auth-content" },
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }