var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { nav: "", dense: "", "data-test-id": "main-menu" } },
    _vm._l(_vm.menu, function (item, index) {
      return _c(
        "div",
        { key: index },
        [
          item.text
            ? _c(
                "div",
                {
                  staticClass: "pa-1 mt-2 overline",
                  attrs: { "data-test-id": "main-menu-item" + index },
                },
                [_vm._v(" " + _vm._s(item.text) + " ")]
              )
            : _vm._e(),
          _c("nav-menu", { attrs: { menu: item.items } }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }