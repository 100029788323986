import utilities from './../../utilities/utilities';

const showToast = ({ state, commit }, message) => {
    if (state.toast.show) commit('hideToast')

    setTimeout(() => {
        commit('showToast', {
            color: 'black',
            message,
            timeout: 3000
        })
    })
}

const showError = ({ state, commit }, { message = 'Failed!', error }) => {
    if (state.toast.show) commit('hideToast')

    setTimeout(() => {
        commit('showToast', {
            color: 'error',
            message: message + ' ' + error.message,
            timeout: 10000
        })
    })
}

const showSuccess = ({ state, commit }, message) => {
    if (state.toast.show) commit('hideToast')

    setTimeout(() => {
        commit('showToast', {
            color: 'success',
            message,
            timeout: 3000
        })
    })
}

const checkIfUserIsAdmin = ({ state, commit }, { vm }) => {
    return new Promise((resolve, reject) => {
        const params = ['utility', 'session', 'isSuperAdmin'];

        vm.$rest.get({ params }, result => {
            commit('setUserAdminState', result.data);
            resolve();
        });
    });
}

const selectProject = ({ state, commit }, project) => {
    commit('setSelectedProject', project);
}

const unselectProject = ({ state, commit }) => {
    commit('resetSelectedProject');
}

const resetUserPermissions = ({ state, commit }) => {
    commit('resetUser');
}

const setFilters = ({ state, commit, dispatch }, { filters, vm }) => {
    return new Promise(resolve => {
        dispatch('removeFilters')
            .then(() => {                
                filters.forEach(_filter => {
                   commit('addFilter', { filter: _filter });
                });

                dispatch('setFiltersPossibleValues', { filters, vm }).then(() => {
                    commit('setInitialFilters', { filters: utilities.clone(state.filters) });
                });
            });
    });
}

const setFilteredPossibleValues = ({ state, commit }, { filter, relatedFilters, vm }) => {
    return new Promise((resolve, reject) => {
        const params = ['filter', 'expansion', state.panelId];

        vm.$rest.post({ params }, { filter, relatedFilters }, result => {
            commit('setValues', { filter: filter, values: result.body.map(value => value[filter.column.name]), valueType: 'possibleValues' });
        });
    });
}

const setFiltersPossibleValues = ({ state, commit }, { filters, vm }) => {
    return new Promise((resolve, reject) => {
        const expansionParams = ['filter', 'expansion'];
        const factParams = ['filter', 'fact'];

        if (state.panelId) {
            expansionParams.push(state.panelId);
            factParams.push(state.panelId);
        } else if (state.reportId) {
            expansionParams.push('report', state.reportId);
            factParams.push('report', state.reportId);
        }

        vm.$rest.get({ params: expansionParams }, expansionResult => {
            vm.$rest.get({ params: factParams }, factResult => {
                const expansionFilters = filters.filter(_filter => !_filter.table.id);

                expansionFilters.forEach(_expansionFilter => {
                    const foundValues = (expansionResult.body || [])
                        .find(_expansionResult => _expansionResult.table === _expansionFilter.table.name && _expansionResult.column === _expansionFilter.column.name);

                    let values = [];

                    if (foundValues) {
                        values = foundValues.values.map(value => value[_expansionFilter.column.name]);
                    }
                    
                    commit('setValues', { filter: _expansionFilter, values, valueType: 'possibleValues' });
                });

                const factFilters = filters.filter(_filter => _filter.table.id);

                factFilters.forEach(_factFilter => {
                    const foundValues = (factResult.body || [])
                        .find(_factResult => _factResult.table === _factFilter.table.name && _factResult.column === _factFilter.column.name);

                    let values = [];

                    if (foundValues) {
                        values = foundValues.values.map(value => value[_factFilter.column.name]);
                    }
                    commit('setValues', { filter: _factFilter, values, valueType: 'possibleValues' });
                });
                resolve(filters);
            });
        });
    });
}

const removeFilters = ({ state, commit }) => {
    return new Promise((resolve, reject) => {
        commit('removeAllFilters');

        resolve(state.filters);
    });
}

const applyFilters = ({ state, commit }) => {
    return new Promise((resolve, reject) => {
        state.filters.forEach(filter => {
            if (filter.type !== 'preset-date' && filter.type !== 'date') {
                filter.unappliedValues.forEach(value => {
                    commit('addValue', { filter, value, valueType: 'appliedValues' });
                });

                commit('emptyFilterValues', { filter, valueType: 'unappliedValues' });
            }
        });

        resolve(state.filters);
    });
}

const setFilterValues = ({ state, commit }, { filter, values }) => {
    return new Promise((resolve, reject) => {
        const filterIndex = state.filters.indexOf(filter);
        values = Array.isArray(values) ? values : [values];

        if (filterIndex !== -1) {
            commit('emptyFilterValues', { filter: state.filters[filterIndex], valueType: 'unappliedValues' });

            values.forEach(value => {
                if (!state.filters[filterIndex].appliedValues.includes(value)) {
                    commit('addValue', { filter: state.filters[filterIndex], value, valueType: 'unappliedValues' });
                }
            });

            state.filters[filterIndex].appliedValues.forEach((appliedValue, index) => {
                if (!values.includes(appliedValue)) {
                    commit('removeValue', { filter: state.filters[filterIndex], value: appliedValue, valueType: 'appliedValues' });
                }
            });
        }

        resolve(state.filters);
    });
}

const removeFilterValue = ({ state, commit }, { filter, value }) => {
    return new Promise(resolve => {
        const filterIndex = state.filters.indexOf(filter);

        if (filterIndex !== -1) {
            if (state.filters[filterIndex].appliedValues.includes(value)) {
                commit('removeValue', { filter: state.filters[filterIndex], value, valueType: 'appliedValues' });
            }

            if (state.filters[filterIndex].unappliedValues.includes(value)) {
                commit('removeValue', { filter: state.filters[filterIndex], value, valueType: 'unappliedValues' });
            }

            if (state.filters[filterIndex].values.includes(value)) {
                commit('removeValue', { filter: state.filters[filterIndex], value, valueType: 'values' });
            }
        }
    });
}

const addBreadCrumbItem = ({ state, commit }, item) => {
    commit('setBreadCrumbItem', { item });
}

const resetBreadCrumb = ({ state, commit }) => {
    commit('deleteBreadCrumb');
}

const clickBreadCrumbItem = ({ state, commit }, item) => {
    commit('openBreadCrumbItem', { item });
}

const changeFilterIsOpenedState = ({ state, commit }, value) => {
   
    commit('updateFilterIsOpenedState', value );
}


export default {
    showToast,
    showError,
    showSuccess,
    checkIfUserIsAdmin,
    selectProject,
    unselectProject,
    resetUserPermissions,
    setFilters,
    setFilteredPossibleValues,
    setFiltersPossibleValues,
    removeFilters,
    applyFilters,
    setFilterValues,
    removeFilterValue,
    addBreadCrumbItem,
    clickBreadCrumbItem,
    resetBreadCrumb,
    changeFilterIsOpenedState
}