<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn text :icon="$vuetify.breakpoint.smAndDown" v-on="on">
        <flag-icon
          data-test-id="flag-icon"
          :round="$vuetify.breakpoint.smAndDown"
          :flag="currentLocale().flag"
        ></flag-icon>
        <v-icon data-test-id="arrow-icon" v-if="showArrow" right
          >mdi-chevron-down</v-icon
        >
      </v-btn>
    </template>

    <v-list dense nav>
      <v-list-item
        v-for="(locale, index) in locales"
        :key="locale.code"
        @click="setLocale(locale.code)"
      >
        <flag-icon
          :kIndex="index + 1"
          :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']"
          :flag="locale.flag"
        ></flag-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import FlagIcon from "../common/FlagIcon";

/*
|---------------------------------------------------------------------
| Language Switcher Component
|---------------------------------------------------------------------
|
| Locale menu to choose the language based on the locales present in
| vue-i18n locales available array
|
*/
export default {
  components: {
    FlagIcon
  },
  props: {
    // Show dropdown arrow
    showArrow: {
      type: Boolean,
      default: true
    },
    // Show the country label
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      locales: [],
    }
  },
  created() {
    this.availableLocales();
  },
  methods: {
    currentLocale() {//bu computed değerler fonksiyona çevrildi.
      return this.$i18n.locales.find(i => i.code === this.$storage.get("user.language"));
    },
    availableLocales() {
      this.locales = this.$i18n.locales.filter(i => i.code !== this.$storage.get("user.language"));
    },
    setLocale(locale) {
      this.$storage.set("user.language", locale);
      this.$i18n.locale = locale;
      this.$vuetify.lang.current = locale;
      this.$strings.set(locale);
      this.$emit("locale-changed", locale);
      this.availableLocales();
      // example on how certain languages can be RTL
      if (locale === "ar") {
        this.$vuetify.rtl = true;
      } else {
        this.$vuetify.rtl = false;
      }
    }  
  }
};
</script>
