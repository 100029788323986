export default {
  // apps quickmenu
  apps: [{
    icon: 'mdi-email-outline',
    text: 'Profile',
    key: 'menu.profile',
    subtitle: 'Hey!',
    link: '/user/edit'
  }],

  // user dropdown menu
  user: [{
    icon: 'mdi-account-box-outline',
    key: 'menu.profile',
    text: 'Profile',
    link: '/user/edit'
  }]
}
