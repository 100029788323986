<template>
  <v-list nav dense data-test-id="main-menu">
    <div v-for="(item, index) in menu" :key="index">
      <div
        v-if="item.text"
        class="pa-1 mt-2 overline"
        :data-test-id="'main-menu-item' + index"
      >
        {{ item.text }}
      </div>
      <nav-menu :menu="item.items" />
    </div>
  </v-list>
</template>

<script>
import NavMenu from "./NavMenu";

export default {
  components: {
    NavMenu
  },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  }
};
</script>
