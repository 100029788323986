process.env.HOST = process.env.VUE_APP_HOME_HOST;
process.env.PORT = process.env.VUE_APP_HOME_PORT;

module.exports = {
  server: {
    url: process.env.VUE_APP_API_URL
  },
  homeUrl: process.env.VUE_APP_HOME_URL,
  keycloak: {
    init: {
        // Use 'login-required' to always require authentication
        // If using 'login-required', there is no need for the router guards in router.js
        checkLoginIframe: false,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
      },
    config: {
        url: process.env.VUE_APP_OIDC_URI,
        clientId: process.env.VUE_APP_OIDC_CLIENTID,
        realm: process.env.VUE_APP_OIDC_REALM,
        redirectUrl: process.env.VUE_APP_OIDC_REDIRECT_URL
    }
  },
  // eGovernmentLoginURLOptions: {
  //   url: 'https://giris.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController?response_type=code&state=random_value&scope=Kimlik-Dogrula',
  //   clientId: '&client_id=c2f3b979-a709-4780-89c1-7dbd3817ddc3',
  //   redirectURI: `&redirect_uri=${encodeURIComponent('http://localhost/#/panels')}`
  // },
  storageName: 'localStorage',
  showUserManagement: true,
  showGroupSelection: true,
  showPanelRuleButton: false,
  useTokenLogin: false,
  enableLog: false,
  logoPath: 'foresight.png',
  showLogoAsInfo: false,
  showEGovernmentLogin: false,
  showApplicationLogin: true,
  loginTypes: ['oidc', 'applicationLogin']
  // defaultGroups: [{ groupName: 'ForesightReadOnlyUsers' }, { groupName: 'ForesightAdmins' }, { groupName: 'demo' }]
  // queryTokenParameterName: 'code',
  // customerLogoName: 'cbddoLogo',
  // customerText: 'TÜRKİYE CUMHURİYETİ CUMHURBAŞKANLIĞI Dijital Dönüşüm Ofisi',
  // customerSubtext: 'Kurum Portallerinde Sunulan Hizmetlerin Kullanım Sayılarının Analizi',
};
