<template>
  <v-container :data-test-id="'container-' + index">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
      :data-test-id="'menu-' + index"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateText"
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
          prepend-icon="mdi-calendar"
          clearable
          @click:clear="clearData"
          :data-test-id="'text-field-' + index"
        >
          ></v-text-field
        >
      </template>
      <v-date-picker
        v-model="dateData[0]"
        @input="closeMenu"
        color="primary lighten-1"
        header-color="primary"
        :data-test-id="'date-picker-' + index"
      ></v-date-picker>
    </v-menu>
  </v-container>
</template>
<script>
export default {
  props: ["value", "label", "index"],
  data: () => ({
    menu: false,
    dateData: []
  }),
  methods: {
    closeMenu() {
      this.$emit("value-changed", this.dateData);
      this.menu = false;
    },
    emit(event) {
      this.$eventBus.$emit(event);
    },
    clearData() {
      this.dateData = [];
      this.$emit("value-changed", "");
    }
  },
  watch: {
    value: function(newVal, oldVal) {
      this.dateData = newVal || [];
    }
  },
  created() {
    this.dateData = this.value || "";
  },
  computed: {
    dateText: {
      get() {
        let date1 = "";
        if (this.dateData && this.dateData.length > 0)
          date1 =
            new Date(this.dateData).getDate() +
            "." +
            (new Date(this.dateData).getMonth() + 1) +
            "." +
            new Date(this.dateData).getFullYear();

        return date1;
      },
      set(newValue) {
        let date1 = "";
        if (newValue)
          date1 =
            new Date(newValue).getDate() +
            "." +
            (new Date(newValue).getMonth() + 1) +
            "." +
            new Date(newValue).getFullYear();
        return date1;
      }
    }
  }
};
</script>
<style scoped>
.container {
  padding-left: 0px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 300px;
  min-width: 200px;
  z-index: 99999;
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
</style>
