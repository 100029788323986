var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _vm.isRouterLoaded
        ? _c(
            _vm.currentLayout,
            { tag: "component" },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: _vm.toast.timeout,
            color: _vm.toast.color,
            bottom: "",
          },
          model: {
            value: _vm.toast.show,
            callback: function ($$v) {
              _vm.$set(_vm.toast, "show", $$v)
            },
            expression: "toast.show",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.toast.message) + " "),
          _vm.toast.timeout === 0
            ? _c(
                "v-btn",
                {
                  attrs: { color: "white", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.toast.show = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("common.close")))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }