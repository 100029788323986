var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-grow-1 primary darken-4",
      attrs: { "data-test-id": "tf-card1", height: "70px" },
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3", md: "2", lg: "2" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "d-flex flex-grow-1 primary darken-4",
                  staticStyle: { "padding-top": "10px" },
                  attrs: { height: "50px", "data-test-id": "tf-card2" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "3", lg: "4" } },
                        [
                          _vm.controlEditPanelFilterPermission
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "primary darken-4 ml-1",
                                  attrs: {
                                    icon: "",
                                    large: "",
                                    "data-test-id": "edit-filter-btn",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.emit("edit-filters")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "data-test-id": "edit-filter-icon",
                                      },
                                    },
                                    [_vm._v("mdi-cog-outline")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "3", lg: "4" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary darken-4",
                              attrs: {
                                "data-test-id": "reset-filter-btn",
                                icon: "",
                                large: "",
                              },
                              on: { click: _vm.resetFilters },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    "data-test-id": "reset-filter-icon",
                                  },
                                },
                                [_vm._v("mdi-backup-restore")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "3", lg: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary darken-4",
                              attrs: {
                                "data-test-id": "apply-filter-btn",
                                icon: "",
                                large: "",
                              },
                              on: { click: _vm.applyFilters },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    "data-test-id": "apply-filter-icon",
                                  },
                                },
                                [_vm._v("mdi-filter")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "1", md: "1", lg: "1" } },
                        [
                          _c("v-divider", {
                            staticClass: "mx-0",
                            attrs: { vertical: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "12", sm: "6", md: "8", lg: "10" } }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary darken-4 mt-1 mr-1",
                    attrs: { icon: "", large: "", "data-test-id": "left-btn" },
                    on: { click: _vm.swipeLeft },
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          "x-large": "",
                          "data-test-id": "left-btn-icon",
                        },
                      },
                      [_vm._v(" mdi-chevron-left")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    ref: "content",
                    staticClass: "center",
                    attrs: { id: "content" },
                  },
                  _vm._l(_vm.filters, function (filter, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "internal" },
                      [
                        _c("filter-component", {
                          attrs: {
                            "opened-index": _vm.openedIndex,
                            index: index,
                          },
                          on: {
                            "filter-index-changed": _vm.filterIndexChanged,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary darken-4 mt-1 mr-2 ml-1",
                    attrs: { icon: "", large: "", "data-test-id": "right-btn" },
                    on: { click: _vm.swipeRight },
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          "x-large": "",
                          "data-test-id": "right-btn-icon",
                        },
                      },
                      [_vm._v(" mdi-chevron-right")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }