import en from '../translations/en'
import tr from '../translations/tr'

const supported = ['tr', 'en']
let locale = 'tr'

try {
    // get browser default language
    const { 0: browserLang } = navigator.language.split('-')

    if (supported.includes(browserLang)) locale = browserLang
} catch (e) {
    console.log(e)
}

export default {
    // current locale
    locale,

    // when translation is not available fallback to that locale
    fallbackLocale: 'tr',

    // availabled locales for user selection
    availableLocales: [{
        code: 'tr',
        flag: 'tr',
        label: 'Türkçe',
        messages: tr
    }, {
        code: 'en',
        flag: 'us',
        label: 'English',
        messages: en
    }]
}