var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      staticStyle: { position: "relative", "z-index": "999999" },
      attrs: {
        "offset-y": "",
        left: "",
        transition: "slide-y-transition",
        "data-test-id": "tu-menu",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "elevation-2",
                    attrs: { icon: "", "data-test-id": "tu-btn" },
                  },
                  on
                ),
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "success",
                        dot: "",
                        bordered: "",
                        "offset-x": "10",
                        "offset-y": "10",
                      },
                    },
                    [
                      _c(
                        "v-avatar",
                        {
                          style: { backgroundColor: _vm.user.avatarColor },
                          attrs: { size: "40", "data-test-id": "tu-avatar" },
                        },
                        [_vm._v(" " + _vm._s(_vm.getUserAvatar()) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "", nav: "" } },
        [
          _vm._l(_vm.menu, function (item, index) {
            return _c(
              "v-list-item",
              {
                key: index,
                attrs: {
                  to: item.link,
                  exact: item.exact,
                  disabled: item.disabled,
                  link: "",
                  "data-test-id": "tu-list-" + index,
                },
              },
              [
                _c(
                  "v-list-item-icon",
                  [
                    _c(
                      "v-icon",
                      {
                        class: { "grey--text": item.disabled },
                        attrs: {
                          "data-test-id": "tu-list-icon-" + index,
                          small: "",
                        },
                      },
                      [_vm._v(_vm._s(item.icon))]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-list-item-content",
                  [
                    _c(
                      "v-list-item-title",
                      { attrs: { "data-test-id": "tu-list-title-" + index } },
                      [_vm._v(_vm._s(item.key ? _vm.$t(item.key) : item.text))]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c("v-divider", { staticClass: "my-1" }),
          _c(
            "v-list-item",
            {
              attrs: { "data-test-id": "tu-list-logout" },
              on: { click: _vm.logout },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        small: "",
                        "data-test-id": "tu-list-logout-icon",
                      },
                    },
                    [_vm._v("mdi-logout-variant")]
                  ),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    { attrs: { "data-test-id": "tu-list-logout-title" } },
                    [_vm._v(_vm._s(_vm.$t("menu.logout")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }